// material-ui
import { Grid, Typography } from '@mui/material';

// project imports
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper } from './styling';
import Accordion from 'ui-component/extended/Accordion';

const faqData = [
    {
        id: 1,
        title: 'How does a company apply to the m+a accelerator?',
        content: 'Application can be made via this website: MentorplusAngels.com.'
    },
    {
        id: 2,
        title: 'What does it cost to participate in the m+a accelerator? ',
        content: 'There is no cost to participate, however we ask that you commit to participate fully for the duration of the program'
    },
    {
        id: 3,
        title: 'If the program is free, then why are you doing this?',
        content:
            'As angel investors we see lots of companies. Until very recently, nearly every company was founded by male, college-educated, white guys. While there is nothing wrong with this, it is our sincere belief that there are many potentially great companies for whom the hurdle of getting the first investment is too great. Collectively we want not only want to solve this problem, but be part of any future success story, via assistance or potentially investment.'
    },
    {
        id: 4,
        title: 'What is the definition of “under-represented founder”?',
        content:
            'The definition of “under-represented” is deliberately broad, intended to include, amongst others, women, people of color, people who are physically impaired, veterans, and socially disadvantaged people. The intention is to include people for whom help may not be available in preparing to seek seed funding, and where this help can make the difference between success and failure.'
    },
    {
        id: 5,
        title: 'What is the ideal stage of business development for a company applying to the m+a accelerator? ',
        content:
            'The ideal candidates will be beyond an ideation stage and will have a founder or founding team working full-time on the venture. The company ideally will have a product either ready for market or already being sold. '
    },
    {
        id: 6,
        title: 'What criteria does the m+a accelerator use to select companies for participation in the program?',
        content:
            'Companies will be selected based on a range of factors: leadership and its experience, number of full-time employees, size of the opportunity and business model, product/service readiness for commercial deployment, extent of traction and product/market fit, further go-to-market plan, and prior capital raises if any.'
    },
    {
        id: 7,
        title: 'Must the company be US based or can it be located elsewhere?',
        content: 'The cohort of companies participating in the 2025 the m+a accelerators must be US based, for reasons of logistics.'
    },
    {
        id: 8,
        title: 'What is the program calendar, from application through program end dates?',
        content:
            'There are 4 key dates: November 1st, 2024: Applications due; December 2nd, 2024 Invitations sent;  January 7th, 2025 Program starts; February 28th, 2025 Program ends.'
    },
    {
        id: 9,
        title: 'How is the curriculum presented?',
        content:
            'The m+a accelerator will feature a series of lectures and workshops encompassing both hard and soft skills. Its curriculum will be presented remotely by experts on each topic from the m+a team. '
    },
    {
        id: 10,
        title: 'How does mentorship work? ',
        content:
            'Companies will be assigned two mentors from the m+a team who have experience in the relevant market sector (e.g. consumer goods, B2B software) or discipline (e.g. marketing, HR, finance). The two assigned members will provide additional one-on-one amplification of the learnings from lectures and workshops. They will meet with the company on a weekly basis and be available to answer questions and give advice. '
    },
    {
        id: 11,
        title: 'What is meant by hybrid?',
        content:
            'The m+a Accelerator Program will be conducted remotely with the option of live workshop and mentorship meetings in New York City, if and as desirable for individual founders. '
    },
    {
        id: 12,
        title: 'How much time should we plan to devote each week during the program?',
        content: 'Lectures, workshops, and mentorship sessions should require a 6–8-hour commitment in total each week .'
    },
    {
        id: 13,
        title: 'Am I assured of introductions to investors after completing the course. If so, how many?',
        content:
            'The team delivering this m+a accelerator has a broad network of leading angel groups and individual seed investors. It will make introductions into that network for all companies graduating from the program. '
    },
    {
        id: 14,
        title: 'Will m+a members introduce me to their industry contacts?',
        content:
            'A unique feature of the m+a accelerator is that you mentors will actively work with you to introduce you not only to angel groups who invest in companies like yours but also, as appropriate, to their industry contacts to accelerate the success of your startup.'
    },
    {
        id: 15,
        title: 'In which industries do m+a members have expertise?',
        content:
            'As can be seen on our "Team" page, m+a members have deep industry expertise in SaaS, CPG, gaming/digital media, Ed tech and media device/biotech, among others.'
    },
    {
        id: 16,
        title: 'Is there a demo day at the end of the program?',
        content:
            'Not in the traditional sense. But the last segment of the m+a accelerator will focus on perfecting the pitch and accompanying demo by presenting them live to the m+a team. This phase will also include a live simulation of negotiating terms of a raise with investors.'
    },
    {
        id: 17,
        title: 'How likely is it that this course will lead to seed funding?',
        content:
            'Completion of the curriculum will substantially increase probability, speed and size of seed funding for all graduating companies. This is a direct result of numerous factors: improved presentation of the company’s opportunity and solution, detailed execution planning to capture a successful commercial outcome, and mastery of the art and detailed craft of seed funding. '
    },
    {
        id: 18,
        title: 'Can I speak with someone at m+a prior to applying?',
        content: 'If you still have questions, please email hi@mentorsplusangels.com with your question and we will get back to you'
    },
    {
        id: 19,
        title: 'How does the m+a accelerator differ from other accelerators?',
        content:
            'There are many highly successful accelerators poroviding support to founders. We differ from others in that we focus exclusively on underrepresented founders, and we have flipped the standard model on its head:  we take no equity up front and our mentors provide all of the curriculum while at the same time limiting the mentor/founder ratio to 2:1 so mentors develop deep and trusted relationships with underrepresented founders.'
    },
    {
        id: 20,
        title: 'Is the m+a Accelerator Program right for my company?',
        content:
            'The program is aimed at early-stage companies seeking their first round of external funding. That said, companies who do not fit this criteria have successfully completed the accelerator. Check out their details on the Alumni'
    }
];

// ==============================|| SAMPLE PAGE ||============================== //

const FAQPage = () => (
    <WebPageWrapper>
        <SectionWrapper>
            <Grid item xs={12}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Frequently Asked Questions
                </Typography>
                <Typography variant="body2" sx={{ mb: 2 }}>
                    The following questions and answers are intended to give you a clearer picture about our accelerator program. Should you
                    have additional questions or concerns, please get in touch with us{' '}
                    <Typography component="a" href="mailto:hi@mentorsplusangels.com">
                        here
                    </Typography>
                    .
                </Typography>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Accordion data={faqData} />
                </Grid>
            </Grid>
        </SectionWrapper>
    </WebPageWrapper>
);

export default FAQPage;
