// material-ui
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//  third party imports
import Animate from 'react-smooth';

// project imports
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper } from './styling';
import { portfolio } from 'config';
import PortfolioCard from 'ui-component/cards/PortfolioCard';

// ==============================|| SAMPLE PAGE ||============================== //

const PortfolioPage = () => {
    const theme = useTheme();

    let itemsPerLine = 3;

    const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));

    if (lessThanSmall) itemsPerLine = 2;
    if (greaterThanMid) itemsPerLine = 4;

    return (
        <WebPageWrapper>
            <SectionWrapper>
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Our Portfolio
                </Typography>
                <Typography sx={{ mb: 4 }}>
                    m+a team members have invested in over 50 early-stage companies. We prefer to invest in, audacious founders who who have
                    been historically overlooked. Through our mentorship we help maximize the potential of their early-stage companies.
                </Typography>
                <Grid container>
                    {portfolio.map((company, index) => {
                        const delay = parseInt(index / itemsPerLine, 10) * 500;

                        return (
                            <Animate key={index} from={{ opacity: 0 }} to={{ opacity: 1 }} easing="ease-in" begin={delay} duration={600}>
                                <Grid item xs={6} sm={4} md={3} sx={{ pr: { xs: 1, sm: 2 }, mb: 2 }}>
                                    <PortfolioCard {...company} key={index} />
                                </Grid>
                            </Animate>
                        );
                    })}
                </Grid>
            </SectionWrapper>
        </WebPageWrapper>
    );
};

export default PortfolioPage;
