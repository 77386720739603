import { useState, forwardRef } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Fade, Typography } from '@mui/material';

// project imports
import heroPhoto from 'assets/images/NY.jpg';

// third party
import { useInView } from 'react-intersection-observer';

// ==============================|| LANDING - HEADER PAGE ||============================== //

const HeroPage = () => {
    const theme = useTheme();
    const [animate, setAnimate] = useState(true);
    const [ref, inView] = useInView();

    // eslint-disable-next-line
    const Transitions = forwardRef(({ children, position, type, direction, ...others }, ref) => {
        const positionSX = {
            transformOrigin: '0 0 0'
        };

        return (
            <Box ref={ref}>
                <Fade
                    {...others}
                    timeout={{
                        appear: 1500,
                        enter: 1600,
                        exit: 1400
                    }}
                >
                    <Box sx={positionSX}>{children}</Box>
                </Fade>
            </Box>
        );
    });

    return (
        <div>
            <Box ref={ref} component="img" src={heroPhoto} />
            {/* <Typography color="#ffffff" variant="h1" sx={{ position: 'absolute', top: '20%', left: '25%' }}> */}
            <Transitions type="fade" in={animate}>
                <Typography
                    color={theme.palette.primary.light}
                    variant="h1"
                    sx={{
                        position: 'absolute',
                        top: '20%',
                        ml: { xs: 3, sm: 6, md: 12 },
                        fontSize: { xs: '1.75rem', sm: '2.5rem', md: '3rem' },
                        fontWeight: 900,
                        lineHeight: 1.4
                    }}
                >
                    Helping Early-Stage, Under-Represented Founders Succeed
                </Typography>
            </Transitions>
        </div>
    );
};

export default HeroPage;
