// material-ui
import { Grid } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';

// ==============================|| ADMIN DASHBOARD ||============================== //

const AdminDashboard = () => {
    console.log('Admin Dashboard');
    return (
        <MainCard title="Administrator Dashboard">
            <Grid container sx={{ ml: 2, pr: 2 }}>
                <Grid item xs={12} md={12}>
                    Hello Administrator
                </Grid>
            </Grid>
        </MainCard>
    );
};

export default AdminDashboard;
