import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Button, Card, CardActionArea, Chip, Grid, IconButton, Menu, CardMedia, Typography, CardContent } from '@mui/material';

// project imports
import Avatar from '../extended/Avatar';
import { gridSpacing } from 'themes/constant';

// assets
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import userPic from 'assets/images/Simon.jpg';

// ==============================|| PORTFOLIO CARD ||============================== //
const logoImage = require.context('assets/images/portfolio', true);

const PortfolioCard = ({ logo, company, snippet, website }) => {
    const theme = useTheme();
    // const logoImage = logo && logoImages(`./${logo}`).default;
    const logoPic = logoImage(`./${logo}`);

    const [anchorEl, setAnchorEl] = useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Card>
            <CardActionArea
                sx={{
                    p: 2,
                    background: theme.palette.grey[50],
                    border: '1px solid',
                    borderColor: theme.palette.grey[100],
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 20%)',
                        position: 'relative',
                        bottom: 1
                    }
                }}
                href={website}
                target="_blank"
            >
                <CardMedia component="img" src={logoPic} title={company} sx={{ height: '125px', objectFit: 'scale-down' }} />
                <CardContent sx={{ height: '110px', px: 0, py: 0, mt: 1 }} align="center">
                    <Typography variant="caption">
                        <b>{company}</b> {snippet}
                    </Typography>
                </CardContent>
            </CardActionArea>
        </Card>
    );
};

PortfolioCard.propTypes = {
    logo: PropTypes.string,
    company: PropTypes.string,
    snippet: PropTypes.string,
    website: PropTypes.string
};

export default PortfolioCard;
