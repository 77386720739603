import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';
import AdminDashboard from 'views/dashboard/admin/Admin';

// Pages
// const AdminDashboard = Loadable(lazy(() => import('views/dashboard/admin/Admin')));
const UserDetail = Loadable(lazy(() => import('views/users/UserDetail')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const UsersPage = Loadable(lazy(() => import('views/users')));
const ProgramsPage = Loadable(lazy(() => import('views/programs')));
const ProgramDetail = Loadable(lazy(() => import('views/programs/ProgramDetail')));
const ApplicationsPage = Loadable(lazy(() => import('views/applications')));
const ApplicationDetail = Loadable(lazy(() => import('views/applications/ApplicationDetail')));
const SamplePage = Loadable(lazy(() => import('views/sample-page')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/admin',
    element: (
        <AdminGuard>
            <MainLayout />
        </AdminGuard>
    ),
    children: [
        {
            path: '/admin',
            element: <AdminDashboard />
        },
        {
            path: '/admin/programs',
            element: <ProgramsPage />
        },
        {
            path: '/admin/program',
            element: <ProgramDetail />,
            children: [{ path: ':id', element: <ProgramDetail /> }]
        },
        {
            path: '/admin/applications',
            element: <ApplicationsPage />
        },
        {
            path: '/admin/application',
            element: <ApplicationDetail />,
            children: [{ path: ':id', element: <ApplicationDetail /> }]
        },
        {
            path: '/admin/users',
            element: <UsersPage />
        },
        {
            path: '/admin/user',
            element: <UserDetail />,
            children: [{ path: ':id', element: <UserDetail /> }]
        },
        {
            path: '/admin/settings',
            element: <UserSettings />
        },
        {
            path: '/admin/page2',
            element: <PlayPage />
        }
    ]
};

export default MainRoutes;
