import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AdminGuard from 'utils/route-guard/AdminGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';

// Pages
import WebPage from 'views/web-page';
import PortfolioPage from 'views/web-page/PortfolioPage';
import TeamPage from 'views/web-page/TeamPage';
import ProgramPage from 'views/web-page/ProgramPage';
import AlumniPage from 'views/web-page/AlumniPage';
import TermsPage from 'views/web-page/TermsPage';
import FAQPage from 'views/web-page/FAQPage';

// ==============================|| WEB PAGE ROUTING ||============================== //

const WebRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/',
            element: <WebPage />
        },
        {
            path: '/portfolio',
            element: <PortfolioPage />
        },
        {
            path: '/team',
            element: <TeamPage />
        },
        {
            path: '/program',
            element: <ProgramPage />
        },
        {
            path: '/alumni',
            element: <AlumniPage />
        },
        {
            path: '/terms',
            element: <TermsPage />
        },
        {
            path: '/faq',
            element: <FAQPage />
        }
    ]
};

export default WebRoutes;
