// project imports
import HeroPage from './homePage/Hero';
import WhoAreWePage from './homePage/WhoAreWe';
import WhatWeDoSection from './homePage/WhatDoWeDo';
import HowWeCanHelpSection from './homePage/HowWeCanHelp';
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper, SecondWrapper } from './styling';
import { useState } from 'react';

// ==============================|| WEB PAGE WRAPPER ||============================== //

const hero = <HeroPage />;

const WebPage = () => {
    const [showApply, setShowApply] = useState(false);

    const handleApply = () => {
        setShowApply(true);
    };
    const hideApply = () => {
        setShowApply(false);
    };

    return (
        <WebPageWrapper hero={hero} showApply={showApply} hideApply={hideApply} handleApply={handleApply}>
            <SectionWrapper>
                <WhoAreWePage handleApply={handleApply} />
            </SectionWrapper>
            <SecondWrapper>
                <WhatWeDoSection />
            </SecondWrapper>
            <SectionWrapper>
                <HowWeCanHelpSection handleApply={handleApply} />
            </SectionWrapper>
        </WebPageWrapper>
    );
};

export default WebPage;
