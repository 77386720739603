import { useNavigate } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Container, Grid, Link, Stack, Typography } from '@mui/material';
import { IconLogin } from '@tabler/icons';

// styles
const FooterWrapper = styled('div')(({ theme }) => ({
    padding: '35px 0',
    color: '#fff',
    background: theme.palette.background.dark,
    [theme.breakpoints.down('md')]: {
        textAlign: 'center'
    }
}));

const FooterLink = styled(Link)({
    color: '#fff',
    display: 'inline-flex',
    alignItems: 'center',
    textDecoration: 'none !important',
    opacity: '0.8',
    '& svg': {
        fontsize: '1.125rem',
        marginRight: 8
    },
    '&:hover': {
        opacity: '1'
    }
});

// ==============================|| LANDING - FOOTER PAGE ||============================== //

const FooterPage = () => {
    const navigate = useNavigate();
    return (
        <FooterWrapper>
            <Container>
                <Grid container alignItems="center" direction="row" justifyContent="space-between">
                    <Grid item>
                        <Typography variant="caption">Copyright © 2024 Mentors+Angels - All Rights Reserved</Typography>
                    </Grid>
                    <Grid item>
                        <Stack direction="row" spacing={4}>
                            <FooterLink
                                href="https://www.wocintechchat.com/"
                                target="_blank"
                                underline="hover"
                                sx={{
                                    cursor: 'pointer'
                                }}
                            >
                                {' '}
                                Photos by #wocintechchat
                            </FooterLink>
                            <FooterLink
                                sx={{
                                    cursor: 'pointer'
                                }}
                                underline="hover"
                                onClick={() => {
                                    navigate('/login');
                                }}
                            >
                                <IconLogin />
                                Admin Login
                            </FooterLink>
                        </Stack>
                    </Grid>
                </Grid>
            </Container>
        </FooterWrapper>
    );
};

export default FooterPage;
