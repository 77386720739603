// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconAward, IconDashboard, IconFileText, IconHelp, IconUsers } from '@tabler/icons';

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
    id: 'admin-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/admin',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'programs-page',
            title: <FormattedMessage id="programs-page" />,
            type: 'item',
            url: '/admin/programs',
            icon: IconAward,
            breadcrumbs: false
        },
        {
            id: 'applications-page',
            title: <FormattedMessage id="applications-page" />,
            type: 'item',
            url: '/admin/applications',
            icon: IconFileText,
            breadcrumbs: false
        },
        {
            id: 'users-page',
            title: <FormattedMessage id="users-page" />,
            type: 'item',
            url: '/admin/users',
            icon: IconUsers,
            breadcrumbs: false
        },
        {
            id: 'sample-page2',
            title: 'Play Area',
            type: 'item',
            url: '/admin/page2',
            icon: IconHelp,
            breadcrumbs: false
        }
    ]
};

export default admin;
