import { apiGet, apiUpdate, apiPost, apiDelete } from './apiClient';

const getApplicationAPI = async ({ id, programID }) => {
    const url = `/application?id=${id}&programID=${programID}`;
    const response = await apiGet({ url });
    return response;
};

const createInterestAPI = async ({ data }) => {
    const url = `/interest`;
    const response = await apiPost({ url, data });
    return response;
};

const createApplicationAPI = async ({ data }) => {
    const url = `/application`;
    const response = await apiPost({ url, data });
    return response;
};

const getApplicationListAPI = async () => {
    const response = await apiGet({ url: `/application/list` });
    return response;
};

const adminGetApplicationListAPI = async () => {
    const response = await apiGet({ url: `/admin/application/list` });
    return response;
};

const updateApplicationAPI = async ({ id, progID, data }) => {
    const response = await apiUpdate({ url: `/application?id=${id}&progID=${progID}`, data });
    return response;
};

const submitApplicationAPI = async ({ id, data }) => {
    const response = await apiPost({ url: `/application/submit?id=${id}`, data });
    return response;
};

const submitApplicationAdminAPI = async ({ id, data }) => {
    const response = await apiPost({ url: `/application/submit/admin?id=${id}`, data });
    return response;
};

const reviewApplicationAPI = async ({ id, progID, data }) => {
    const url = `/application/review?id=${id}&progID=${progID}`;
    const response = await apiPost({ url, data });
    return response;
};

const bestApplicationAPI = async ({ id, programID, data }) => {
    const url = `/application/best?id=${id}&programID=${programID}`;
    const response = await apiPost({ url, data });
    return response;
};

const changeScoreStatusAPI = async ({ id, programID, status }) => {
    const url = `/application/score/status?applicationID=${id}&programID=${programID}&status=${status}`;
    const response = await apiUpdate({ url });
    return response;
};

const deleteApplicationAPI = async ({ id, programID }) => {
    const url = `/application?id=${id}&programID=${programID}`;
    const response = await apiDelete({ url });
    return response;
};

export {
    createInterestAPI,
    getApplicationAPI,
    getApplicationListAPI,
    createApplicationAPI,
    updateApplicationAPI,
    deleteApplicationAPI,
    submitApplicationAPI,
    submitApplicationAdminAPI,
    adminGetApplicationListAPI,
    reviewApplicationAPI,
    changeScoreStatusAPI,
    bestApplicationAPI
};
