import { useEffect, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Grid, Typography } from '@mui/material';
import { IconSchool, IconCashBanknote, IconUsers } from '@tabler/icons';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Animate from 'react-smooth';

// third party
import { useInView } from 'react-intersection-observer';

// =============================|| LANDING - FEATURE PAGE ||============================= //

const WhatWeDoSection = () => {
    const theme = useTheme();
    const [ref, inView] = useInView();
    const [canBegin1, setCanBegin1] = useState(false);
    const [canBegin2, setCanBegin2] = useState(false);
    const [canBegin3, setCanBegin3] = useState(false);

    useEffect(() => {
        setCanBegin1(inView);
        setCanBegin2(inView);
        setCanBegin3(inView);
    }, [inView]);

    return (
        <Grid container ref={ref}>
            <Grid item xs={12} sx={{ mb: 4 }}>
                <Typography variant="h2">What Do We Do?</Typography>
            </Grid>

            <Animate from={{ opacity: 0 }} to={{ opacity: 1 }} canBegin={canBegin1} easing="ease-in" duration={880}>
                <Grid item xs={12} md={4} sx={{ px: 1, py: 2 }}>
                    <MainCard
                        sx={{
                            height: 320,
                            '& svg': {
                                width: 60,
                                height: 60,
                                color: theme.palette.secondary.main,
                                borderRadius: '14px',
                                p: 1.25,
                                bgcolor: 'primary.light'
                            },
                            ':hover': {
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 20%)'
                            }
                        }}
                    >
                        <Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <IconSchool stroke={1.5} />
                            <Typography variant="h3" align="center">
                                Mentor
                            </Typography>
                            <Typography align="center">
                                Work with entrepreneurs to strengthen their pitches for early-stage investors. Provide input on business
                                proposition, product/service, raise/valuation and financials. Review pitch deck and provide feedback on
                                presentation and supporting materials.
                            </Typography>
                        </Stack>
                    </MainCard>
                </Grid>
            </Animate>

            <Animate from={{ opacity: 0 }} to={{ opacity: 1 }} canBegin={canBegin2} easing="ease-in" duration={880}>
                <Grid item xs={12} md={4} sx={{ px: 1, py: 2 }}>
                    <MainCard
                        sx={{
                            height: 320,
                            '& svg': {
                                width: 60,
                                height: 60,
                                color: theme.palette.secondary.main,
                                borderRadius: '14px',
                                p: 1.25,
                                bgcolor: 'primary.light'
                            },
                            ':hover': {
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 20%)'
                            }
                        }}
                    >
                        <Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <IconCashBanknote stroke={1.5} />
                            <Typography variant="h3" align="center">
                                Invest
                            </Typography>
                            <Typography align="center">
                                Make angel investments through several Angel groups as well as independently. We are associated, amongst
                                other angel investment and mentoring groups, with New York Angels, Harvard Business School Angels, Astia
                                Angels, Broad Street Angels and 1863.
                            </Typography>
                        </Stack>
                    </MainCard>
                </Grid>
            </Animate>

            <Animate from={{ opacity: 0 }} to={{ opacity: 1 }} canBegin={canBegin3} easing="ease-in" duration={880}>
                <Grid item xs={12} md={4} sx={{ px: 1, py: 2 }}>
                    <MainCard
                        sx={{
                            height: 320,
                            '& svg': {
                                width: 60,
                                height: 60,
                                color: theme.palette.secondary.main,
                                borderRadius: '14px',
                                p: 1.25,
                                bgcolor: 'primary.light'
                            },
                            ':hover': {
                                boxShadow: '0 2px 14px 0 rgb(32 40 45 / 20%)'
                            }
                        }}
                    >
                        <Stack direction="column" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <IconUsers stroke={1.5} />
                            <Typography variant="h3" align="center">
                                Advise
                            </Typography>
                            <Typography align="center">
                                Provide advice and on-going business guidance to portfolio companies. We leverage our vertical industry
                                expertise and business contacts to help with strategy, team and execution.
                            </Typography>
                        </Stack>
                    </MainCard>
                </Grid>
            </Animate>
        </Grid>
    );
};

export default WhatWeDoSection;
