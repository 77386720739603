import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    createInterestAPI,
    createApplicationAPI,
    getApplicationListAPI,
    updateApplicationAPI,
    submitApplicationAPI,
    submitApplicationAdminAPI,
    reviewApplicationAPI,
    bestApplicationAPI,
    getApplicationAPI,
    deleteApplicationAPI,
    changeScoreStatusAPI,
    adminGetApplicationListAPI
} from 'APIs/application';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';

// ==============================|| REDUX STORE: APPLICATION DATA ||============================== //

const GET_APPLICATION_LIST = createAsyncThunk('application/list', async () => {
    console.log('calling GET_APPLICATION_LIST');
    const response = await getApplicationListAPI();
    return response;
});

const ADMIN_GET_APPLICATION_LIST = createAsyncThunk('admin/application/list', async () => {
    console.log('calling GET_ADMIN_APPLICATION_LIST');
    const response = await adminGetApplicationListAPI();
    return response;
});

// Upload files - not all files for Applications are private
const uploadFile = async ({ file, name, companyName }) => {
    if (!(file instanceof File)) {
        throw new Error(`Invalid file: ${name}`);
    }
    // Get s3 upload URL
    const nameExtension = file.name.split('.').pop();
    let cleanName = file.name.replace(`.${nameExtension}`, '');
    cleanName = cleanName.replace(/[^a-zA-Z0-9]/g, '');
    cleanName = `${cleanName}.${nameExtension}`;
    const fileName = `${name}-${cleanName}`;
    const path = `applications/${companyName}`;
    const mimeType = file.type;
    // Get signed URL for upload
    const urlResponse = await getFileURLAPI({ fileName, path, mimeType, isPrivate: true });
    // Upload to s3
    const { url } = urlResponse;
    await uploadFileWithURLAPI({ url, file });
    return url.split('?')[0];
};

// Upload any files
const checkForFiles = async ({ data, companyName }) => {
    const { pitchDeck, pitchVideo, logo } = data;
    if (pitchDeck) {
        const url = await uploadFile({ file: pitchDeck, name: 'pitchDeck', companyName });
        delete data.pitchDeck;
        data.pitchDeckURL = url;
    }
    if (pitchVideo) {
        const url = await uploadFile({ file: pitchVideo, name: 'pitchVideo', companyName });
        delete data.pitchVideo;
        data.pitchVideoURL = url;
    }
    if (logo) {
        const url = await uploadFile({ file: logo, name: 'logo', companyName });
        delete data.logo;
        data.logoURL = url;
    }
    return data;
};

const CREATE_INTEREST = createAsyncThunk('interest/create', async ({ data }) => {
    const response = await createInterestAPI({ data });
    return response;
});

const CREATE_APPLICATION = createAsyncThunk('application/create', async ({ data, companyName }) => {
    // Upload any files
    const modifiedData = await checkForFiles({ data, companyName });
    const response = await createApplicationAPI({ data: modifiedData });
    return response;
});

const UPDATE_APPLICATION = createAsyncThunk('application/update', async ({ id, progID, data }) => {
    const response = await updateApplicationAPI({ id, progID, data });
    return response;
});

const DELETE_APPLICATION = createAsyncThunk('application/delete', async ({ id, programID }) => {
    const response = await deleteApplicationAPI({ id, programID });
    return response;
});

const GET_APPLICATION = createAsyncThunk('application/get', async ({ id, programID }) => {
    const response = await getApplicationAPI({ id, programID });
    return response;
});

const REVIEW_APPLICATION = createAsyncThunk('application/review', async ({ id, progID, data }) => {
    const response = await reviewApplicationAPI({ id, progID, data });
    return response;
});

const CHANGE_SCORE_STATUS = createAsyncThunk('application/score/status', async ({ id, programID, status }) => {
    const response = await changeScoreStatusAPI({ id, programID, status });
    return response;
});

const BEST_APPLICATION = createAsyncThunk('application/best', async ({ bestData }) => {
    const { applicationID, programID, bestApplication } = bestData;
    const response = await bestApplicationAPI({ id: applicationID, programID, data: bestApplication });
    return response;
});

const SUBMIT_APPLICATION = createAsyncThunk('application/submit', async ({ id, data }) => {
    // Upload any files
    const modifiedData = await checkForFiles({ data });
    const response = await submitApplicationAPI({ id, data: modifiedData });
    return response;
});

const SUBMIT_APPLICATION_ADMIN = createAsyncThunk('application/submit/admin', async ({ id, data }) => {
    const response = await submitApplicationAdminAPI({ id, data });
    return response;
});

const initialState = {
    initialized: false,
    applications: []
};

const applicationSlice = createSlice({
    name: 'application',
    initialState,
    reducers: {
        create(state, action) {
            state.applications.push(action.payload.data);
        },
        logout(state) {
            state.initialized = false;
            state.applications = [];
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_APPLICATION.fulfilled, (state, action) => {
                state.applications.push(action.payload);
            })
            // .addCase(GET_APPLICATION.fulfilled, (state, action) => {
            //     // TODO: include programID as part of key
            //     state.applications = state.applications.map((application) => {
            //         if (application.id === action.payload.id) {
            //             return { ...application, ...action.payload };
            //         }
            //         return application;
            //     });
            // })
            .addCase(UPDATE_APPLICATION.fulfilled, (state, action) => {
                // TODO: include programID as part of key
                state.applications = state.applications.map((application) => {
                    if (application.id === action.payload.id) {
                        return { ...application, ...action.payload };
                    }
                    return application;
                });
            })
            // .addCase(SUBMIT_APPLICATION.fulfilled, (state, action) => {
            //     state.applications = state.applications.map((application) => {
            //         if (application.id === action.payload.id) {
            //             return { ...application, ...action.payload };
            //         }
            //         return application;
            //     });
            // })
            // .addCase(SUBMIT_APPLICATION_ADMIN.fulfilled, (state, action) => {
            //     state.applications = state.applications.map((application) => {
            //         if (application.id === action.payload.id) {
            //             return { ...application, ...action.payload };
            //         }
            //         return application;
            //     });
            // })
            // .addCase(CHANGE_SCORE_STATUS.fulfilled, (state, action) => {
            //     state.applications = state.applications.map((application) => {
            //         if (application.id === action.payload.id) {
            //             return { ...application, ...action.payload };
            //         }
            //         return application;
            //     });
            // })
            .addCase(REVIEW_APPLICATION.fulfilled, (state, action) => {
                state.applications = state.applications.map((application) => {
                    if (application.id === action.payload.id) {
                        return { ...application, ...action.payload };
                    }
                    return application;
                });
            })
            // .addCase(BEST_APPLICATION.fulfilled, (state, action) => {
            //     state.applications = state.applications.map((application) => {
            //         // check exists in payload
            //         const updatedApplication = action.payload.find((item) => item.id === application.id);
            //         if (updatedApplication) {
            //             return updatedApplication;
            //         }
            //         return application;
            //     });
            // })
            // .addCase(DELETE_APPLICATION.fulfilled, (state, action) => {
            //     const deletedID = action.meta.arg.id;
            //     const deletedProgramID = action.meta.arg.programID;
            //     if (deletedID && deletedProgramID) {
            //         state.applications = state.applications.filter((item) => item.programID !== deletedProgramID || item.id !== deletedID);
            //     }
            // })
            .addCase(GET_APPLICATION_LIST.fulfilled, (state, action) => {
                state.applications = [...action.payload];
                state.initialized = true;
            })
            .addCase(ADMIN_GET_APPLICATION_LIST.fulfilled, (state, action) => {
                state.applications = [...action.payload];
                state.initialized = true;
            });
    }
});

const { create, logout } = applicationSlice.actions;
const initialized = (state) => state?.application?.initialized;
const applications = (state) => state?.application?.applications || [];

export {
    CREATE_INTEREST,
    GET_APPLICATION_LIST,
    UPDATE_APPLICATION,
    SUBMIT_APPLICATION,
    REVIEW_APPLICATION,
    BEST_APPLICATION,
    CREATE_APPLICATION,
    GET_APPLICATION,
    ADMIN_GET_APPLICATION_LIST,
    CHANGE_SCORE_STATUS,
    SUBMIT_APPLICATION_ADMIN,
    DELETE_APPLICATION,
    initialized,
    applications,
    create,
    logout
};
export default applicationSlice.reducer;
