import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getUserAPI, updateUserAPI } from 'APIs/user';
import { accountLoginAPI, updateUserPasswordAPI } from 'APIs/account';
import { getFileURLAPI, uploadFileWithURLAPI } from 'APIs/file';

const initialState = {
    isLoggedIn: false,
    isAdmin: false,
    isInitialized: false,
    invoicesInitialized: false,
    pricesInitialized: false,
    invoices: [],
    user: {},
    org: {},
    prices: {}
};

// ==============================|| REDUX STORE: ACCOUNT DATA ||============================== //

// Called after login to initilize user and org data
const ACCOUNT_LOGIN = createAsyncThunk('account/login', async () => {
    const response = await accountLoginAPI();
    return response;
});

const GET_USER = createAsyncThunk('account/user/get', async () => {
    const response = await getUserAPI();
    return response;
});

const UPDATE_USER = createAsyncThunk('account/user/update', async ({ data = {}, file }) => {
    // First Upload photo if necessary
    if (file) {
        const uploadURL = await getFileURLAPI({ fileName: file.name, path: 'userPhoto', mimeType: file.type, isPrivate: false });
        data.photoURL = uploadURL.url.split('?')[0];
        await uploadFileWithURLAPI({ url: uploadURL.url, file });
    }
    // Now update User data
    const response = await updateUserAPI({ data });
    return response;
});

const UPDATE_USER_PASSWORD = createAsyncThunk('user/password', async ({ oldPassword, newPassword }) => {
    const response = await updateUserPasswordAPI({ oldPassword, newPassword });
    return response;
});

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        LOGIN(state, action) {
            state.isLoggedIn = true;
            state.user = { ...state.user, ...action.payload };
        },
        LOGOUT(state) {
            state.isLoggedIn = false;
            state.isInitialized = false;
            state.invoicesInitialized = false;
            state.invoices = [];
            state.pricesInitialized = false;
            state.prices = [];
            state.org = {};
            if (state.defaultProgramID) {
                delete state.defaultProgramID;
            }
            const keepKeys = ['givenName', 'familyName', 'email'];
            Object.keys(state.user).forEach((key) => keepKeys.includes(key) || delete state.user[key]);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(GET_USER.fulfilled, (state, action) => {
                state.isInitialized = true;
                state.user = { ...state.user, ...action.payload };
            })
            .addCase(ACCOUNT_LOGIN.fulfilled, (state, action) => {
                const userData = action.payload.user;
                const orgData = action.payload.org;
                state.isInitialized = true;
                state.user = { ...state.user, ...userData };
                state.org = { ...state.org, ...orgData };
            })
            .addCase(UPDATE_USER.fulfilled, (state, action) => {
                state.user = { ...state.user, ...action.payload };
            });
    }
});

const loginStatus = (state) => state?.account?.isLoggedIn;
const userData = (state) => state?.account?.user;
const orgData = (state) => state?.account?.org;
const invoices = (state) => state?.account?.invoices || [];
const invoicesInitialized = (state) => state?.account?.invoicesInitialized;
const prices = (state) => state?.account?.prices || [];
const pricesInitialized = (state) => state?.account?.pricesInitialized;
const { LOGIN, LOGOUT } = accountSlice.actions;

export {
    LOGIN,
    LOGOUT,
    GET_USER,
    UPDATE_USER,
    ACCOUNT_LOGIN,
    UPDATE_USER_PASSWORD,
    loginStatus,
    userData,
    orgData,
    invoices,
    invoicesInitialized,
    prices,
    pricesInitialized
};
export default accountSlice.reducer;
