import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Typography, Button } from '@mui/material';

// project imports
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper, SecondWrapper } from './styling';
import youngFounders from 'assets/images/youngFounders.jpg';
import laptopRevenue from 'assets/images/laptopRevenue.jpg';
import founderLaptop from 'assets/images/founderLaptop.jpg';

// ==============================|| SAMPLE PAGE ||============================== //

const PortfolioPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const [showApply, setShowApply] = useState(false);

    const handleApply = () => {
        setShowApply(true);
    };
    const hideApply = () => {
        setShowApply(false);
    };

    const handleFAQ = () => {
        navigate('/faq');
    };

    return (
        <WebPageWrapper showApply={showApply} hideApply={hideApply} handleApply={handleApply}>
            <SectionWrapper>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2" sx={{ mb: 2 }}>
                            This Program Is Unique
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            The m+a Accelerator Program is delivered by a team with deep operating experience as senior corporate leaders,
                            entrepreneurs, and investment professionals and with a broad network of leading angel groups and individual seed
                            investors.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Typography sx={{ mb: 2 }}>
                            The m+a accelerator is open to all under-represented founders who need help in seeking seed funding.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            For our second cohort, we will accept 6-8 companies across a broad range of industries to participate in a
                            7-week program of lectures, workshops, and 1-on-1 mentorship sessions.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            The m+a accelerator will be conducted remotely with live meetings in New York City, as required for individual
                            companies.{' '}
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            Applications are due <b>November 1, 2024</b> and acceptances with be sent before the end of November, and the
                            program will start <b>January 7, 2025</b>.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>There is no cost to participate. Absolutely none!</Typography>
                        <Typography sx={{ mb: 2 }}>
                            It will end with a demo day and introductions to potential seed investors and investor groups.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ pl: 2 }}>
                        <Box
                            component="img"
                            sx={{
                                height: { md: 300 },
                                maxWidth: { xs: 400, sm: 500, md: 300, lg: 300 },
                                borderRadius: 1
                            }}
                            src={youngFounders}
                        />
                    </Grid>
                </Grid>
            </SectionWrapper>
            <SecondWrapper sx={{ px: { xs: 2, sm: 4, md: 8 } }}>
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Why Apply?
                </Typography>
                <Grid container>
                    <Grid item xs={12} md={4} lg={3}>
                        <Box
                            component="img"
                            sx={{
                                // height: { md: 300 },
                                maxWidth: { xs: 400, sm: 500, md: 300, lg: 250 },
                                borderRadius: 1
                            }}
                            src={laptopRevenue}
                        />
                    </Grid>
                    <Grid item xs={12} md={8} lg={9} sx={{ pl: 2 }}>
                        <Typography sx={{ mb: 2 }}>
                            Too many early stage companies struggle to get their first investment. This investment could make the difference
                            between success and failure.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            Completion of the curriculum will substantially increase probability, speed and size of seed funding for all
                            graduating companies.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            You will learn from successful entrepreneurs, corporate executives and experienced angel investors, developing
                            relationships that can enable your dream to become a reality.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            Gain advice, encouragement and moral support from mentors as well as your fellow founders.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>Put your company in the best possible position to make the next step.</Typography>
                        <Typography variant="h4" color={theme.palette.grey[600]}>
                            Join the m+a accelerator to help secure your first external financing
                        </Typography>
                        <Grid container justifyContent="flex-end" sx={{ mt: 1 }}>
                            <Grid item>
                                <Button variant="contained" color="warning" onClick={handleApply}>
                                    Apply
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SecondWrapper>
            <SectionWrapper>
                <Grid container>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h2" sx={{ mb: 2 }}>
                            What Will We Learn?
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            Lectures and workshops will move your team up the path to successful funding by covering these topics:
                        </Typography>
                        <Grid container>
                            <Grid item sx={{ ml: 4 }}>
                                <Typography variant="subtitle1">• So, you want to be an entrepreneur?</Typography>
                                <Typography variant="subtitle1">• Five questions every entrepreneur must answer.</Typography>
                                <Typography variant="subtitle1">• Building organization capacity.</Typography>
                                <Typography variant="subtitle1">• Leadership, building competitive advantage and execution.</Typography>
                                <Typography variant="subtitle1">• Value proposition and monetization strategies</Typography>
                                <Typography variant="subtitle1">• Go-to-market strategies and plans.</Typography>
                                <Typography variant="subtitle1">• Technology, and how to use it.</Typography>
                                <Typography variant="subtitle1">• Financials, financial planning and budgeting.</Typography>
                                <Typography variant="subtitle1">
                                    • Capital raising fundamentals including early-stage financing alternatives.
                                </Typography>
                                <Typography variant="subtitle1">• Startup valuations, and cap tables.</Typography>
                                <Typography variant="subtitle1">• Pitches and pitch decks: the good, bad and ugly.</Typography>
                                <Typography variant="subtitle1">• “Demo” day, pitching to potential investors</Typography>
                                <Typography variant="subtitle1">• Role-playing exercise for negotiating funding terms.</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={4} sx={{ pl: 2, mt: 2 }}>
                        <Box
                            component="img"
                            sx={{
                                height: { md: 400 },
                                maxWidth: { xs: 400, sm: 500, md: 300, lg: 300 },
                                borderRadius: 1
                            }}
                            src={founderLaptop}
                            // src="https://mentorsplusangels-data.s3.amazonaws.com/images/maap1.jpg"
                        />
                    </Grid>
                </Grid>
            </SectionWrapper>
            <SecondWrapper sx={{ px: { xs: 2, sm: 4, md: 8 } }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h2" sx={{ mb: 2 }}>
                            Next Steps
                        </Typography>
                        {/* <Typography sx={{ mb: 2 }}>
                            Our Spring Program has just completed and we will be running our next session later in the year. To be one of
                            the first to know when teh application window opens, please complete the interest form.
                        </Typography> */}

                        <Typography sx={{ mb: 2 }}>
                            We are now accepting applications for our next cohort with a start date of <b>January 7th, 2025</b>. The final
                            day that you can apply is <b>November 1st, 2024</b>, and successful companies will be notified by{' '}
                            <b>December 2nd, 2024</b>. We anticipate the end date of the program will be <b>February 28th, 2025</b>.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>
                            Please remember that this is a program aimed at under-represented founders, and priority will be given to teams
                            featuring females, minorities or other under-represented groups.
                        </Typography>
                        <Typography sx={{ mb: 2 }}>We look forward to hearing from you.</Typography>
                    </Grid>
                    <Grid container sx={{ mt: 4 }}>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center" justifyContent="flex-start" sx={{ mt: 1 }}>
                                <Grid item sx={{ pr: 2 }}>
                                    <Button variant="contained" color="secondary" onClick={handleFAQ}>
                                        FAQ
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h4" color={theme.palette.grey[600]}>
                                        More questions?
                                    </Typography>
                                    <Typography variant="subtitle1" color={theme.palette.grey[600]}>
                                        Check out our Frequently Asked Questions page
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Grid container alignItems="center" justifyContent="flex-end" sx={{ mt: 1 }}>
                                <Grid item sx={{ pr: 2 }}>
                                    <Typography variant="h4" color={theme.palette.grey[600]}>
                                        Otherwise, what are you waiting for?
                                        {/* Register your interest for our next program */}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Button variant="contained" color="warning" onClick={handleApply}>
                                        Apply
                                        {/* Register */}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SecondWrapper>
        </WebPageWrapper>
    );
};
export default PortfolioPage;
