import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
    createProgramAPI,
    getProgramListAPI,
    updateProgramAPI,
    deleteProgramAPI,
    getProgramSummaryAPI,
    deleteProgramCategoryAPI
} from 'APIs/program';

// ==============================|| REDUX STORE: PROGRAM DATA ||============================== //

const GET_PROGRAM_LIST = createAsyncThunk('program/list', async () => {
    console.log('calling GET_PROGRAM_LIST');
    const response = await getProgramListAPI();
    return response;
});

const GET_PROGRAM_SUMMARY = createAsyncThunk('program/summary', async ({ id }) => {
    console.log('calling GET_PROGRAM_SUMMARY');
    const response = await getProgramSummaryAPI({ id });
    return response;
});

const UPDATE_PROGRAM = createAsyncThunk('program/update', async ({ id, data }) => {
    const response = await updateProgramAPI({ id, data });
    return response;
});

const CREATE_PROGRAM = createAsyncThunk('program/create', async ({ data }) => {
    const response = await createProgramAPI({ data });
    return response;
});

const DELETE_PROGRAM = createAsyncThunk('program/delete', async ({ id }) => {
    const response = await deleteProgramAPI({ id });
    return response;
});

const DELETE_PROGRAM_CATEGORY = createAsyncThunk('program/category/delete', async ({ programID, categoryID }) => {
    const response = await deleteProgramCategoryAPI({ programID, categoryID });
    return response;
});

const initialState = {
    initialized: false,
    defaultProgramID: '',
    programs: [],
    programSummary: {}
};

const programSlice = createSlice({
    name: 'program',
    initialState,
    reducers: {
        create(state, action) {
            state.programs.push(action.payload.data);
        },
        SET_DEFAULT_PROGRAM(state, action) {
            if (action.payload.defaultProgramID) {
                state.defaultProgramID = action.payload.defaultProgramID;
            }
        },
        logout(state) {
            state.initialized = false;
            state.defaultProgramID = '';
            state.programs = [];
            state.programSummary = {};
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(CREATE_PROGRAM.fulfilled, (state, action) => {
                state.programs.push(action.payload);
            })
            .addCase(UPDATE_PROGRAM.fulfilled, (state, action) => {
                state.programs = state.programs.map((program) => {
                    if (program.id === action.payload.id) {
                        return { ...program, ...action.payload };
                    }
                    return program;
                });
            })
            .addCase(DELETE_PROGRAM_CATEGORY.fulfilled, (state, action) => {
                state.programs = state.programs.map((program) => {
                    if (program.id === action.payload.id) {
                        return { ...program, ...action.payload };
                    }
                    return program;
                });
            })
            .addCase(DELETE_PROGRAM.fulfilled, (state, action) => {
                const deletedID = action.meta.arg.id;
                if (deletedID) {
                    state.programs = state.programs.filter((item) => item.id !== deletedID);
                }
            })
            .addCase(GET_PROGRAM_LIST.fulfilled, (state, action) => {
                state.programs = [...action.payload];
                state.initialized = true;
            })
            .addCase(GET_PROGRAM_SUMMARY.fulfilled, (state, action) => {
                state.programSummary = { ...action.payload };
            })
            .addMatcher(
                (action) => action.type.includes('account/login/fulfilled'),
                (state, action) => {
                    console.log('>>>>>>>>>>>>>>>>HERE');
                    const defaultProgramID = action.payload.user?.defaultProgramID;
                    console.log(defaultProgramID);
                    if (defaultProgramID) {
                        state.defaultProgramID = defaultProgramID;
                    }
                }
            )
            .addMatcher(
                (action) => action.type.includes('user/update/admin/fulfilled'),
                (state, action) => {
                    if (action.payload?.programs) {
                        const programsUpdated = action.payload.programs.map((item) => item.id);
                        console.log('programsUpdated', programsUpdated);
                        state.programs = [...state.programs.filter((t) => !programsUpdated.includes(t.id)), ...action.payload.programs];
                    }
                }
            );
    }
});

const { create, logout, SET_DEFAULT_PROGRAM } = programSlice.actions;
const initialized = (state) => state?.program?.initialized;
const programs = (state) => state?.program?.programs;

export {
    GET_PROGRAM_LIST,
    UPDATE_PROGRAM,
    CREATE_PROGRAM,
    SET_DEFAULT_PROGRAM,
    DELETE_PROGRAM,
    DELETE_PROGRAM_CATEGORY,
    GET_PROGRAM_SUMMARY,
    initialized,
    programs,
    create,
    logout
};
export default programSlice.reducer;
