import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MentorGuard from 'utils/route-guard/MentorGuard';

// sample page routing
const DashboardMentor = Loadable(lazy(() => import('views/dashboard/mentor/Mentor')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));
const ApplicationsPage = Loadable(lazy(() => import('views/applications')));
const ApplicationDetail = Loadable(lazy(() => import('views/applications/ApplicationDetail')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/mentor',
    element: (
        <MentorGuard>
            <MainLayout />
        </MentorGuard>
    ),
    children: [
        {
            path: '/mentor',
            element: <DashboardMentor />
        },
        {
            path: '/mentor/settings',
            element: <UserSettings />
        },
        {
            path: '/mentor/applications',
            element: <ApplicationsPage />
        },
        {
            path: '/mentor/application',
            element: <ApplicationDetail />,
            children: [{ path: ':id', element: <ApplicationDetail /> }]
        },
        {
            path: '/mentor/page2',
            element: <PlayPage />
        }
    ]
};

export default MainRoutes;
