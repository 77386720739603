import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardActionArea, Grid, Typography } from '@mui/material';

// project imports
import Avatar from '../extended/Avatar';
import { gridSpacing } from 'themes/constant';

// ==============================|| USER SIMPLE CARD ||============================== //

const TeamCard = ({ person, showDetails }) => {
    const theme = useTheme();
    const { avatar, name, title, linkedIn } = person || {};

    const handleClick = () => {
        console.log('Click...');
        showDetails({ person });
    };

    return (
        <Card>
            <CardActionArea
                sx={{
                    p: 2,
                    background: theme.palette.grey[50],
                    border: '1px solid',
                    borderColor: theme.palette.grey[100],
                    ':hover': {
                        boxShadow: '0 2px 14px 0 rgb(32 40 45 / 20%)',
                        position: 'relative',
                        bottom: 1
                    }
                }}
                onClick={handleClick}
            >
                <Grid container direction="row" alignItems="center" spacing={gridSpacing}>
                    <Grid item xs={4}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs zeroMinWidth>
                                <Avatar alt={name} {...avatar} sx={{ width: 72, height: 72 }} />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Grid container direction="column">
                            <Grid item xs zeroMinWidth>
                                <Typography variant="h3">{name}</Typography>
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="caption">{title}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Card>
    );
};

TeamCard.propTypes = {
    avatar: PropTypes.object,
    name: PropTypes.string,
    title: PropTypes.string
};

export default TeamCard;
