const validateEmail = (emailString) => {
    const validRegex =
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    if (!String(emailString).toLowerCase().match(validRegex)) {
        return false;
    }
    return true;
};

const validatePhone = (phoneString) => {
    if (phoneString.length < 10) {
        return false;
    }
    const validRegex = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]*$/g;

    if (!String(phoneString).match(validRegex)) {
        return false;
    }
    return true;
};

export { validateEmail, validatePhone };
