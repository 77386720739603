import { useState } from 'react';

// material-ui
import { Typography, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

// project imports
import TeamCard from 'ui-component/cards/TeamCard';
import { team } from 'config';

//  third party imports
import Animate from 'react-smooth';
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper } from './styling';
import TeamMemberPopUp from 'ui-component/pop-ups/TeamMemberPopUp';

// ==============================|| TEAM PAGE ||============================== //

const TeamPage = () => {
    const theme = useTheme();
    const [selectedPerson, setSelectedPerson] = useState();
    let itemsPerLine = 2;

    const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const greaterThanMid = useMediaQuery(theme.breakpoints.up('md'));

    if (lessThanSmall) itemsPerLine = 1;
    if (greaterThanMid) itemsPerLine = 3;

    const showPerson = ({ person }) => {
        setSelectedPerson(person);
    };

    const closePopUp = () => {
        setSelectedPerson();
    };

    return (
        <WebPageWrapper>
            <SectionWrapper>
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Our Team
                </Typography>
                <Typography sx={{ mb: 4 }}>
                    m+a team members have diverse and extensive business experience. We are advisors and board members in early-stage
                    companies, public corporations and non-profits across many different industries.
                </Typography>
                <Grid container sx={{ pl: 4, pr: 4 }}>
                    {team.map((person, index) => {
                        const delay = parseInt(index / itemsPerLine, 10) * 500;

                        return (
                            <Animate key={index} from={{ opacity: 0 }} to={{ opacity: 1 }} easing="ease-in" begin={delay} duration={600}>
                                <Grid item xs={12} sm={6} md={4} sx={{ pr: { xs: 0, sm: 2 }, mb: 2 }}>
                                    <TeamCard person={person} showDetails={showPerson} key={index} />
                                </Grid>
                            </Animate>
                        );
                    })}
                </Grid>
            </SectionWrapper>
            <TeamMemberPopUp open={!!selectedPerson} person={selectedPerson} handleClose={closePopUp} />
        </WebPageWrapper>
    );
};

export default TeamPage;
