// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconFileText, IconHelp } from '@tabler/icons';

// ===========================|| APPLICANT MENU OPTIONS ||=========================== //

const founder = {
    id: 'founder-menu',
    title: <FormattedMessage id="dashboard" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/founder',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'applications-page',
            title: <FormattedMessage id="applications-page" />,
            type: 'item',
            url: '/founder/application/list',
            icon: IconFileText,
            breadcrumbs: false
        },
        {
            id: 'sample-page2',
            title: 'Play Area',
            type: 'item',
            url: '/founder/play',
            icon: IconHelp,
            breadcrumbs: false
        }
    ]
};

export default founder;
