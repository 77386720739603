import PropTypes from 'prop-types';

// material-ui
import { Button, Grid, Typography, Box } from '@mui/material';

// project imports
import FadeInWhenVisible from '../Animation';

// =============================||  ||============================= //

const WhoAreWePage = ({ handleApply }) => (
    <Grid container>
        <Grid item xs={12}>
            <Typography variant="h2">Who Are We?</Typography>
        </Grid>
        <Grid container>
            <Grid item xs={12} md={8}>
                <Typography sx={{ mt: 2, pr: 4 }} variant="subtitle1">
                    Mentors+Angels is a group of individuals who are dedicated to helping under-represented founders fully capture the
                    future of their start-ups.
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    The team has deep business experience as senior corporate leaders, entrepreneurs and investment professionals.
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    We work collectively as a team to identify opportunities and help coach founders to success.
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    We make individual investments in early stage companies and also bring opportunities to our investor communities in
                    order to help secure your first external financing.
                </Typography>
            </Grid>
            <Grid item xs={12} md={4} sx={{ mt: { xs: 2, md: 0 }, pl: { xs: 0, md: 2 } }}>
                <FadeInWhenVisible>
                    <Box
                        component="img"
                        sx={{
                            maxWidth: { xs: '100%' },
                            borderRadius: 0.5
                        }}
                        src="https://mentorsplusangels-data.s3.amazonaws.com/images/charles-forerunner-HOME.jpg"
                    />
                </FadeInWhenVisible>
            </Grid>
        </Grid>

        <Grid item sx={{ mt: 2 }}>
            <Typography variant="h3" sx={{ mb: 2 }} color="secondary">
                Our 2025 accelerator program is currently receiving applications
            </Typography>
            <Button variant="contained" color="warning" onClick={handleApply}>
                Apply
            </Button>
        </Grid>
    </Grid>
);

WhoAreWePage.propTypes = {
    handleApply: PropTypes.func
};

export default WhoAreWePage;
