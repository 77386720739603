import admin from './admin';
import mentor from './mentor';
import founder from './founder';

// ===========================|| MENU ITEMS ||=========================== //

const menuItems = ({ userType }) => {
    const items = [];
    if (userType === 'admin') {
        items.push(admin);
    } else if (userType === 'mentor') {
        items.push(mentor);
    } else {
        items.push(founder);
    }
    return { items };
};

export default menuItems;
