import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLoading: false,
    showBackdrop: false,
    backdropMessage: '',
    action: false,
    showMessage: false,
    message: undefined,
    variant: 'alert',
    alertSeverity: 'success',
    transition: 'Fade',
    close: true,
    actionButton: false
};

const showSuccessMessage = ({ state, message }) => {
    const newState = {
        ...state,
        isLoading: false,
        showMessage: !!message,
        alertSeverity: 'success',
        variant: 'alert',
        message
    };
    return newState;
};

const showLoading = ({ state, backdropMessage }) => {
    const newState = {
        ...state,
        isLoading: true,
        close: true,
        message: undefined,
        showMessage: false,
        showBackdrop: true,
        backdropMessage
    };
    return newState;
};

// ==============================|| REDUX STORE: STATUS DATA ||============================== //

const statusSlice = createSlice({
    name: 'status',
    initialState,
    reducers: {
        USER_MESSAGE(state, action) {
            console.log('>>>>>USER MESSAGE');
            console.log(action);
            const { payload } = action;
            console.log(state);
            state.action = !state.action;
            state.showMessage = true;
            state.message = payload.message ? payload.message.toString() : initialState.message;
            state.alertSeverity = payload.alertSeverity ? payload.alertSeverity : initialState.alertSeverity;
            state.isLoading = false;
            state.showBackdrop = false;
        },
        CLOSE_MESSAGE(state) {
            console.log('Closing message');
            state.isLoading = false;
            state.showMessage = false;
            state.message = undefined;
            state.backdropMessage = undefined;
            state.showBackdrop = false;
        },
        START_LOADING(state, action) {
            state.isLoading = true;
            state.showMessage = false;
            state.message = undefined;
            state.backdropMessage = undefined;
            if (action.payload?.backdrop) {
                state.showBackdrop = true;
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                (action) => action.type.includes('user/password/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User Password' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/admin/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User Password' })
            )
            .addMatcher(
                (action) => action.type.includes('account/user/update/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User' })
            )
            .addMatcher(
                (action) => action.type.includes('application/delete/pending'),
                (state) => showLoading({ state, backdropMessage: 'Deleting Application' })
            )
            .addMatcher(
                (action) => action.type.includes('application/submit/pending'),
                (state) => showLoading({ state, backdropMessage: 'Submitting Application' })
            )
            .addMatcher(
                (action) => action.type.includes('application/submit/admin/pending'),
                (state) => showLoading({ state, backdropMessage: 'Submitting Application' })
            )
            .addMatcher(
                (action) => action.type.includes('program/category/delete/pending'),
                (state) => showLoading({ state, backdropMessage: 'Checking Data' })
            )
            .addMatcher(
                (action) => action.type.includes('program/update/pending'),
                (state) => showLoading({ state })
            )
            .addMatcher(
                (action) => action.type.includes('program/summary/pending'),
                (state) => showLoading({ state, backdropMessage: 'Fetching Program Summary' })
            )
            .addMatcher(
                (action) => action.type.includes('program/delete/pending'),
                (state) => showLoading({ state, backdropMessage: 'Deleting Program' })
            )
            .addMatcher(
                (action) => action.type.includes('user/update/admin/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User' })
            )
            .addMatcher(
                (action) => action.type.includes('account/pm/default/pending'),
                (state) => showLoading({ state })
            )
            .addMatcher(
                (action) => action.type.includes('user/lock/pending'),
                (state) => showLoading({ state })
            )
            .addMatcher(
                (action) => action.type.includes('user/delete/pending'),
                (state) => showLoading({ state })
            )
            .addMatcher(
                (action) => action.type.includes('user/list/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating User List' })
            )
            .addMatcher(
                (action) => action.type.includes('user/review/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating Reviews' })
            )
            .addMatcher(
                (action) => action.type.includes('account/pm/pending'),
                (state) => showLoading({ state, backdropMessage: 'Validating Payment Method' })
            )
            .addMatcher(
                (action) => action.type.includes('account/prices/pending'),
                (state) => showLoading({ state, backdropMessage: 'Fetching Prices' })
            )
            .addMatcher(
                (action) => action.type.includes('user/create/pending'),
                (state) => showLoading({ state, backdropMessage: 'Creating User' })
            )
            .addMatcher(
                (action) => action.type.includes('application/score/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating Score' })
            )
            .addMatcher(
                (action) => action.type.includes('application/score/status/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating Score Status' })
            )
            .addMatcher(
                (action) => action.type.includes('application/best/pending'),
                (state) => showLoading({ state, backdropMessage: 'Updating Application' })
            )
            .addMatcher(
                (action) => action.type.includes('application/update/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Updating Application/Uploading Documents';
                    state.showMessage = false;
                    state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.includes('program/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.showMessage = false;
                    state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.includes('application/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Creating Application/Uploading Documents';
                    state.showMessage = false;
                    state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.includes('interest/create/pending'),
                (state) => {
                    state.isLoading = true;
                    state.showBackdrop = true;
                    state.backdropMessage = 'Registering Interest';
                    state.showMessage = false;
                    state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.includes('account/user/update/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Your information has been successfully updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Your password has been successfully updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/password/admin/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'User password changed. Please remember to inform the user.' })
            )
            .addMatcher(
                (action) => action.type.includes('program/update/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Program Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('application/update/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Application Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('account/pm/default/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Default Card Changed' })
            )
            .addMatcher(
                (action) => action.type.includes('application/delete/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Application Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('application/review/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Application Review Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('application/submit/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Application Submitted Successfully' })
            )
            .addMatcher(
                (action) => action.type.includes('application/submit/admin/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Application Submitted Successfully' })
            )
            .addMatcher(
                (action) => action.type.includes('application/score/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Score Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('application/score/status/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Score Status Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('application/best/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Applications Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/delete/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'The User Has Been Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('user/update/admin/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'User Updated' })
            )
            .addMatcher(
                (action) => action.type.includes('user/create/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'New User Created and Email Sent' })
            )
            .addMatcher(
                (action) => action.type.includes('program/create/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Program Created' })
            )
            .addMatcher(
                (action) => action.type.includes('program/delete/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Program Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('program/category/delete/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Program Category Deleted' })
            )
            .addMatcher(
                (action) => action.type.includes('application/create/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Thank you for applying. You will receive an email confirming receipt.' })
            )
            .addMatcher(
                (action) => action.type.includes('interest/create/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Thank you for registering your interest in our program.' })
            )
            .addMatcher(
                (action) => action.type.includes('account/pm/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Payment Method Added' })
            )
            .addMatcher(
                (action) => action.type.includes('account/prices/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'Current Prices Retrieved' })
            )
            .addMatcher(
                (action) => action.type.includes('user/lock/fulfilled'),
                (state) => showSuccessMessage({ state, message: 'User Updated' })
            )
            .addMatcher(
                (action) => action.type.endsWith('/rejected'),
                (state, action) => {
                    console.log('REJECTED!!!');
                    console.log(`Message: ${action?.error?.message}`);
                    // state.showMessage = true;
                    // state.message = action?.error?.message;
                    // state.alertSeverity = 'error';
                    state.isLoading = false;
                    state.showBackdrop = false;
                    // state.variant = 'alert';
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/fulfilled'),
                (state) => {
                    state.isLoading = false;
                    state.showBackdrop = false;
                    // state.showMessage = false;
                    // state.message = undefined;
                }
            )
            .addMatcher(
                (action) => action.type.endsWith('/pending'),
                (state) => {
                    state.open = false;
                    state.isLoading = true;
                    // state.showMessage = false;
                    // state.message = undefined;
                }
            );
    }
});
export const isLoading = (state) => state?.status?.isLoading;
export default statusSlice.reducer;
export const { USER_MESSAGE, START_LOADING, END_LOADING, CLOSE_MESSAGE } = statusSlice.actions;
