// third-party
import Chip from 'ui-component/extended/Chip';
import { applicationStatus, scoreStatus } from 'config';

const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
});

const getRowID = (row) => {
    let rowID = parseInt(row, 10);
    rowID += 1;
    return rowID.toString();
};

const getUserType = (userType) => {
    if (!userType) {
        return 'Unknown';
    }
    return userType.charAt(0).toUpperCase() + userType.toLowerCase().slice(1);
};

const formatCurrency = (amount) => {
    if (!amount) return null;
    const ret = formatter.format(amount);
    return ret;
};

const formatCurrencyString = ({ amount, currency }) => {
    if (!amount || !currency) return null;
    let currencySymbol = 'US$';
    if (currency.toLowerCase() === 'gbp') {
        currencySymbol = '£';
    }
    if (!amount) {
        return `${currencySymbol}0.00`;
    }
    // const amountInt = parseInt(amount,10);
    const displayAmount = (Math.round(amount) / 100).toFixed(2);
    return `${currencySymbol}${displayAmount.toLocaleString()}`;
};

const formatPercentage = ({ amount }) => {
    if (!amount) return null;
    return `${amount} %`;
};

const getApplicationStatus = (rowStatus) => {
    const { color = 'error', shortText = 'Error' } = applicationStatus.find((item) => item.id === rowStatus) || {};
    return <Chip chipcolor={color} label={shortText} size="small" />;
};

const getScoreStatus = ({ status = 'unscored', smallSize }) => {
    const { color = 'error', name = 'Error' } = scoreStatus.find((item) => item.id === status) || {};
    return smallSize ? <Chip chipcolor={color} label={name} size="small" /> : <Chip chipcolor={color} label={name} />;
};

const handlePayClick = (e) => {
    e.stopPropagation();
    console.log('Click>>>');
};

const getPaidStatus = (status) => {
    if (!status) return '';
    let text = '';
    let color = '';
    switch (status) {
        case 'paid':
            text = 'Paid';
            color = 'success';
            break;
        case 'draft':
            text = 'Draft';
            color = 'warning';
            break;
        default:
            text = 'Invalid Status';
            color = 'error';
            break;
    }
    return <Chip chipcolor={color} label={text} size="small" onClick={(e) => handlePayClick(e)} />;
};

const formatName = (row) => {
    if (!row) return 'Unknown';
    if (row.name) return row.name;
    if (row.fullName) return row.fullName;
    if (row.givenName && row.familyName) return `${row.givenName} ${row.familyName}`;
    if (row.givenName) return row.givenName;
    if (row.familyName) return row.familyName;
    return 'Unknown';
};

const formatDecimal = (value) => {
    if (!value) return '';
    let decimalValue = value;
    if (Number.isNaN(value)) {
        decimalValue = parseFloat(value);
    }
    // const decimalValue
    return (Math.round(decimalValue * 100) / 100).toFixed(1);
};

export {
    getRowID,
    getUserType,
    formatCurrency,
    formatDecimal,
    getPaidStatus,
    getApplicationStatus,
    getScoreStatus,
    formatName,
    formatPercentage,
    formatCurrencyString
};
