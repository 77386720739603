// third-party
import { configureStore } from '@reduxjs/toolkit';
import { loadState } from './persistState';
import accountReducer from './slices/accountSlice';
import menuReducer from './slices/menu';
import statusReducer from './slices/statusSlice';
import programReducer from './slices/programSlice';
import applicationReducer from './slices/applicationSlice';
import userReducer from './slices/userSlice';

// ==============================|| REDUX - MAIN STORE ||============================== //

const persistedState = loadState();

const store = configureStore({
    reducer: {
        menu: menuReducer,
        status: statusReducer,
        program: programReducer,
        application: applicationReducer,
        user: userReducer,
        account: accountReducer
    },
    preloadedState: persistedState
});

export default store;
