// material-ui
import { Typography } from '@mui/material';

// project imports
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper } from './styling';

// ==============================|| SAMPLE PAGE ||============================== //

const TermsPage = () => (
    <WebPageWrapper>
        <SectionWrapper>
            <Typography variant="h2" sx={{ mb: 2 }}>
                Terms & Conditions
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Your Acceptance
            </Typography>
            <Typography sx={{ mb: 4 }}>
                By using this site (the “Site”), you signify your agreement to all terms, conditions, and notices contained or referenced
                herein (“Terms of Use”). If you do not agree to the Terms of Use, please do not use the Site. Mentors + Angels (“Mentors +
                Angels”) reserves the right, in its discretion, to update or revise the Terms of Use. Please check periodically for changes.
                Your continued use of the Site following the posting of any changes to the Terms of Use constitutes acceptance of those
                changes. You acknowledge and agree that Mentors + Angels may monitor your use of the Site to enforce compliance with the
                Terms of Use. You may visit and browse the Site without giving any personal information. However, you may need to register
                with the Site to gain access to receive newsletters or promotional materials, or to use or access certain other Site
                features. All personal information is handled in accordance with our Privacy Policy. By using this Site, you signify your
                agreement to our Privacy Policy.
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
                General
            </Typography>
            <Typography sx={{ mb: 2 }}>
                You may not use the Site for any purpose that is unlawful or prohibited by the Terms of Use, or to solicit the performance
                of any illegal activity or other activity which infringes the rights of Mentors + Angels or others. Without limiting the
                foregoing, you may not attempt to gain unauthorized access to any portion of the Site, or any other systems or networks
                connected to the Site by hacking or any other illegitimate means.
            </Typography>
            <Typography sx={{ mb: 2 }}>
                Additional terms and conditions may apply to specific portions of the Site, and such terms and conditions are made a part of
                the Terms of Use by reference. You agree to abide by such other terms and conditions. If there is a conflict between the
                Terms of Use and the terms posted for or applicable to a specific portion of the Site, the latter terms shall control with
                respect to your use of that portion of the Site.
            </Typography>
            <Typography sx={{ mb: 4 }}>
                While we make reasonable efforts to provide accurate and timely information on the Site, you should not assume that the
                information provided is always complete, reliable, or up to date.
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Third-Party Sites
            </Typography>
            <Typography sx={{ mb: 2 }}>
                The Site may link you to other websites on the Internet. Such other websites may not be under the control of Mentors +
                Angels, and you acknowledge that Mentors + Angels is not responsible for the accuracy, copyright compliance, legality,
                decency, or any other aspect(s) of the content of sites that are not under the control of Mentors + Angels. The inclusion of
                such a link does not imply endorsement of the site by Mentors + Angels or any association between Mentors + Angels and the
                operators of such other site.
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
                Linking to the Site
            </Typography>
            <Typography sx={{ mb: 4 }}>
                Generally, Mentors + Angels does not mind if you have a text link from your website to the Site. However, you may not frame
                or “mirror” the Site or incorporate pieces of it into a different website or product. Links to the Site must clearly
                identify Mentors + Angels as the source of information displayed and preserve the integrity of web pages associated with the
                Site without alteration. Further, you may not link to the Site if you create or post any illegal, obscene or offensive
                content, or if the link in any way has a negative impact on our reputation.
            </Typography>

            <Typography variant="h4" sx={{ mb: 2 }}>
                Proprietary Rights
            </Typography>
            <Typography sx={{ mb: 2 }}>
                Mentors + Angels is the owner of all intellectual property rights, including, without limitation, all copyrights and
                trademarks, associated with the Site, with all rights reserved, exclusive of content submitted, transmitted, posted, or
                otherwise provided by third parties, which is owned by the licensors of such content. You acknowledge and agree that the
                content and materials available on the Site, including without limitation all text, graphics, user interfaces, visual
                interfaces, photographs, trademarks, logos, taglines, video media, artwork, design, structure, selection, coordination,
                expression, “look and feel” and the arrangement thereof, are protected by copyrights, trademarks, service marks or other
                proprietary rights and laws. You agree not to sell, license, rent, modify, distribute, copy, reproduce, transmit, publicly
                display, publicly perform, publish, adapt, edit, compile or create derivative works from such content or materials. Use of
                the content or materials for any purpose not expressly permitted in the Terms of Use is prohibited. You acknowledge and
                agree that the unauthorized use of the contents and materials on the Site could cause irreparable harm to Mentors + Angels
                or its licensors and that in the event of such unauthorized use, Mentors + Angels or its licensors shall be entitled to an
                injunction in addition to any other remedies available at law or in equity.
            </Typography>
            <Typography sx={{ mb: 2 }}>
                Documents available on the Site may be copied for personal use only on the condition that copyright and source indications
                are also copied, no modifications are made, and the document is copied entirely. However, some documents, photos, and other
                content may have been published on the Site with the permission of the relevant copyright owners (who are not Mentors +
                Angels). All rights in such documents, photos, and other content are reserved to their owners, and permission to copy or
                otherwise exploit them must be requested and obtained directly from the copyright owners.
            </Typography>
            <Typography sx={{ mb: 4 }}>
                If you have any questions about the license granted herein or if you would like to request additional rights, please write
                to hi@mentorsplusangels.com.
            </Typography>
            <Typography variant="h4" sx={{ mb: 2 }}>
                User Content / Feedback
            </Typography>
            <Typography sx={{ mb: 2 }}>
                You may be able to post or send messages, text, graphics, photos, videos, data, feedback, suggestions, ideas, concepts, or
                other materials (“User Content”) on or through the Site’s forum or other features. You agree that you are and shall remain
                solely responsible for the contents of any User Content you submit and that you will not submit material that is unlawful,
                defamatory, abusive or obscene. You also agree that any User Content submitted will be treated as non-confidential and
                nonproprietary. By posting or sending such User Content, you represent and warrant that (i) you have the right to use,
                distribute, and/or reproduce all materials contained therein, and such materials do not violate any right of any third
                party, including copyright, trademark, patent, trade secret, privacy or other personal or proprietary right(s), (ii) the
                User Content is accurate, and (iii) the User Content does not violate any other provisions in the Terms of Use. In
                consideration of your use of the Site, you hereby grant to Mentors + Angels a perpetual, irrevocable, worldwide,
                transferable, royalty-free, non-exclusive right and license to use, reproduce, modify, adapt, publish, display, translate,
                sub-license, create derivative works from, and distribute such User Content in any medium or technology now known or later
                developed. You agree that Mentors + Angels shall be entitled to unrestricted use of such User Content for any purpose
                whatsoever without any compensation to you or your licensors.
            </Typography>
            <Typography sx={{ mb: 4 }}>
                Some of the User Content displayed on the Site may be submitted, transmitted, posted, or otherwise provided by users of the
                Site over whom Mentors + Angels exercises no control. You may find User Content objectionable, inappropriate, or offensive.
                Mentors + Angels disavows responsibility for User Content to the extent permitted by law and has no obligation to screen
                User Content in advance. Mentors + Angels may in its sole discretion at any time remove or alter any User Content or take
                any other actions with respect to User Content. Mentors + Angels has no liability or responsibility to users for performance
                or nonperformance of such activities. Should you come across any content that you find objectionable, inappropriate, or
                offensive, you can bring it to our attention by emailing hi@mentorsplusangels.com.
            </Typography>
        </SectionWrapper>
    </WebPageWrapper>
);

export default TermsPage;
