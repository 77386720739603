// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { IconDashboard, IconFileText, IconHelp } from '@tabler/icons';

// ===========================|| MENTOR MENU OPTIONS ||=========================== //

const mentor = {
    id: 'mentor-menu',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            url: '/mentor',
            icon: IconDashboard,
            breadcrumbs: false
        },
        {
            id: 'application-page',
            title: <FormattedMessage id="applications" />,
            type: 'item',
            url: '/mentor/applications',
            icon: IconFileText,
            breadcrumbs: false
        }
        // {
        //     id: 'sample-page2',
        //     title: 'Play Area - Mentor',
        //     type: 'item',
        //     url: '/mentor/play',
        //     icon: IconHelp,
        //     breadcrumbs: false
        // }
    ]
};

export default mentor;
