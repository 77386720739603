// material-ui
import { Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

//  third party imports
import Animate from 'react-smooth';

// project imports
import WebPageWrapper from './page-wrapper/WebPageWrapper';
import { SectionWrapper } from './styling';
import { alumni } from 'config';
import AlumniCard from 'ui-component/cards/AlumniCard';

// ==============================|| SAMPLE PAGE ||============================== //

const AlumniPage = () => {
    const theme = useTheme();

    let itemsPerLine = 2;

    const lessThanSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const greaterThanLarge = useMediaQuery(theme.breakpoints.up('lg'));

    if (lessThanSmall) itemsPerLine = 1;
    if (greaterThanLarge) itemsPerLine = 3;

    return (
        <WebPageWrapper>
            <SectionWrapper>
                <Typography variant="h2" sx={{ mb: 2 }}>
                    Our Alumni Companies
                </Typography>
                <Typography sx={{ mb: 4 }}>
                    Meet the companies and exceptional founders who have successfully completed our m+a Accelerator Program.
                </Typography>
                <Grid container>
                    {alumni.map((company, index) => {
                        const delay = parseInt(index / itemsPerLine, 10) * 500;

                        return (
                            <Animate key={index} from={{ opacity: 0 }} to={{ opacity: 1 }} easing="ease-in" begin={delay} duration={600}>
                                <Grid item xs={12} sm={6} md={4} sx={{ pr: { xs: 1, sm: 2 }, mb: 2 }}>
                                    <AlumniCard {...company} key={index} />
                                </Grid>
                            </Animate>
                        );
                    })}
                </Grid>
            </SectionWrapper>
        </WebPageWrapper>
    );
};

export default AlumniPage;
