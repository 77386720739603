import { useRoutes } from 'react-router-dom';

// routes
import AdminRoutes from './AdminRoutes';
import LoginRoutes from './LoginRoutes';
import WebRoutes from './WebRoutes';
import MainRoutes from './MentorRoutes';
import FounderRoutes from './FounderRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([WebRoutes, LoginRoutes, AdminRoutes, MainRoutes, FounderRoutes]);
    // return useRoutes([WebRoutes, LoginRoutes]);
}
