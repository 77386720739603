import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import FounderGuard from 'utils/route-guard/FounderGuard';

// sample page routing
const FounderDashboard = Loadable(lazy(() => import('views/dashboard/founder/Founder')));
const UserSettings = Loadable(lazy(() => import('views/user-settings')));
const PlayPage = Loadable(lazy(() => import('views/sample-page2')));

// ==============================|| MAIN ROUTING ||============================== //

const FounderRoutes = {
    path: '/founder',
    element: (
        <FounderGuard>
            <MainLayout />
        </FounderGuard>
    ),
    children: [
        {
            path: '/founder',
            element: <FounderDashboard />
        },
        {
            path: '/founder/settings',
            element: <UserSettings />
        },
        {
            path: '/founder/page2',
            element: <PlayPage />
        }
    ]
};

export default FounderRoutes;
