import PropTypes from 'prop-types';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';

// ==============================|| APPLICATION CONFIRMATION POPUP ||============================== //

const SubmittedApplicationPopUp = ({ open, handleClose }) => (
    <Dialog open={open} onClose={handleClose} fullWidth>
        <DialogTitle>Successfully Submitted Application</DialogTitle>
        <DialogContent>
            <Grid container>
                <Grid item xs={12}>
                    <Typography sx={{ mt: 1 }} variant="h4">
                        Thank you for applying to the m+a Accelerator Program
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        This application has now been submitted and you will soon receive an email confirmation.
                    </Typography>
                    <Typography sx={{ mt: 1 }}>
                        All applications will be reviewed and would will be notified by March 7, 2024 if you have been successful or not.
                    </Typography>
                    <Typography variant="body2" sx={{ mt: 1 }}>
                        For any queries or issues please&nbsp;
                        <Typography component="a" href="mailto:hi@mentorsplusangels.com">
                            contact us
                        </Typography>
                        .
                    </Typography>
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button sx={{ mr: 2 }} variant="contained" size="small" onClick={handleClose}>
                Done
            </Button>
        </DialogActions>
    </Dialog>
);

SubmittedApplicationPopUp.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default SubmittedApplicationPopUp;
