const errorExists = ({ errors = [], field }) => {
    const errorRecord = errors.find((item) => item.field === field);
    return !!errorRecord;
};

const getErrorText = ({ errors = [], field }) => {
    const errorRecord = errors.find((item) => item.field === field);
    if (errorRecord) {
        return errorRecord.errorText || 'undefined error';
    }
    return undefined;
};

export { getErrorText, errorExists };
