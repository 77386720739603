import logo from 'assets/images/logo_inv.png';
import logoDark from 'assets/images/logo.png';

// ==============================|| LOGO ||============================== //

const Logo = ({ dark = false, large = false }) => {
    if (dark) {
        return large ? <img src={logoDark} alt="m+a" width="150" /> : <img src={logoDark} alt="m+a" height="46" />;
    }
    return <img src={logo} alt="m+a" width="116" />;
};

export default Logo;
