// material-ui
import { styled } from '@mui/material/styles';

// ==============================|| STYLING FOR WEB PAGES ||============================== //

const SectionWrapper = styled('div')(({ theme }) => ({
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 80,
    paddingRight: 80,
    [theme.breakpoints.down('md')]: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40
    }
}));

const SecondWrapper = styled('div')(({ theme }) => ({
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 80,
    paddingRight: 80,
    backgroundColor: theme.palette.grey[100],
    [theme.breakpoints.down('md')]: {
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 40,
        paddingRight: 40
    }
}));

export { SecondWrapper, SectionWrapper };
