import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { FOUNDER_DASHBOARD_PATH, MENTOR_DASHBOARD_PATH } from 'config';

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AdminGuard = ({ children }) => {
    const { isLoggedIn, userType } = useAuth();
    const navigate = useNavigate();
    console.log('AuthGuard userType', userType);

    useEffect(() => {
        if (isLoggedIn) {
            if (userType === 'mentor') {
                navigate(MENTOR_DASHBOARD_PATH, { replace: true });
            } else if (userType === 'founder') {
                navigate(FOUNDER_DASHBOARD_PATH, { replace: true });
            }
        } else {
            navigate('/login', { replace: true });
        }
    }, [isLoggedIn, navigate, userType]);

    return children;
};

AdminGuard.propTypes = {
    children: PropTypes.node
};

export default AdminGuard;
