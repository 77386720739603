import { apiGet, apiUpdate, apiPost, apiDelete } from './apiClient';

const getProgramSummaryAPI = async ({ id }) => {
    const response = await apiGet({ url: `/program/summary?id=${id}` });
    return response;
};

const createProgramAPI = async ({ data }) => {
    const response = await apiPost({ url: `/program`, data });
    return response;
};

const getProgramListAPI = async () => {
    const response = await apiGet({ url: `/program/list` });
    return response;
};

const updateProgramAPI = async ({ id, data }) => {
    const response = await apiUpdate({ url: `/program?id=${id}`, data });
    return response;
};

const deleteProgramAPI = async ({ id }) => {
    const response = await apiDelete({ url: `/program?id=${id}` });
    return response;
};

const deleteProgramCategoryAPI = async ({ programID, categoryID }) => {
    const response = await apiDelete({ url: `/program/category?programID=${programID}&categoryID=${categoryID}` });
    return response;
};

export { getProgramListAPI, createProgramAPI, updateProgramAPI, deleteProgramAPI, getProgramSummaryAPI, deleteProgramCategoryAPI };
