import { validatePhone, validateEmail } from 'utils/validations';

// Validation for Application Form
const formValidation = ({ activeStep, formData }) => {
    const newErrors = [];
    // Check Step
    if (activeStep === 0) {
        const { givenName, familyName, phone, email, jobTitle } = formData;
        if (!givenName) {
            newErrors.push({ field: 'givenName', errorText: 'You must enter a first name' });
        }
        if (!familyName) {
            newErrors.push({ field: 'familyName', errorText: 'You must enter your last/family name' });
        }
        if (!phone) {
            newErrors.push({ field: 'phone', errorText: 'You include a phone number in case we need to speak to you' });
        } else if (!validatePhone(phone)) {
            newErrors.push({ field: 'phone', errorText: 'Please enter a valid phone number' });
        }
        if (!email) {
            newErrors.push({ field: 'email', errorText: 'Please enter an email for us to confirm your application' });
        } else if (!validateEmail(email)) {
            newErrors.push({ field: 'email', errorText: 'Please enter a valid email address' });
        }
        if (!jobTitle) {
            newErrors.push({ field: 'jobTitle', errorText: 'We would like to know your role to better evaluate your application' });
        }

        return newErrors;
    }

    if (activeStep === 1) {
        const { companyName, address, websiteURL, fullTime, partTime, legalStructure, funding, advisors } = formData;
        if (!companyName) {
            newErrors.push({ field: 'companyName', errorText: 'You must tell us the name of your company' });
        }
        if (!address) {
            newErrors.push({ field: 'address', errorText: 'Please tell us where you company is based' });
        }
        if (!websiteURL) {
            newErrors.push({ field: 'websiteURL', errorText: 'Please tell us your website address, else type none' });
        }
        if (!fullTime) {
            newErrors.push({ field: 'fullTime', errorText: 'If no full time employees enter zero' });
        }
        if (!partTime) {
            newErrors.push({ field: 'partTime', errorText: 'If no part time employees enter zero' });
        }
        if (!advisors) {
            newErrors.push({ field: 'advisors', errorText: 'If no advisors enter zero' });
        }
        if (!legalStructure) {
            newErrors.push({ field: 'legalStructure', errorText: 'Please tell us what kind of company you are' });
        }
        if (!funding && funding !== 0) {
            newErrors.push({ field: 'funding', errorText: 'If you have not yet received any funding, plese enter zero' });
        }

        return newErrors;
    }

    if (activeStep === 2) {
        const { addMarket, traction, industry, busTech, oneLiner, businessModel, devStage } = formData;
        if (!oneLiner) {
            newErrors.push({ field: 'oneLiner', errorText: 'Please tell about your company using one sentance' });
        }
        if (!businessModel) {
            newErrors.push({ field: 'businessModel', errorText: 'Please describe your business model' });
        }
        if (!industry) {
            newErrors.push({ field: 'industry', errorText: 'Please use this field to classify your company' });
        }
        if (!busTech) {
            newErrors.push({ field: 'busTech', errorText: 'Please use this field to classify your company' });
        }
        if (!traction) {
            newErrors.push({ field: 'traction', errorText: 'You must tell us what traction you have' });
        }
        if (!addMarket) {
            newErrors.push({ field: 'addMarket', errorText: 'Please indicate the size of the addressable market' });
        }
        if (!devStage) {
            newErrors.push({ field: 'devStage', errorText: 'You must tell us the stage your product is at' });
        }

        return newErrors;
    }

    if (activeStep === 3) {
        const { pitchDeck, pitchDeckURL } = formData;
        if (!pitchDeck && !pitchDeckURL) {
            newErrors.push({ field: 'pitchDeck', errorText: 'You must upload your current pitch deck' });
        }
        return newErrors;
    }

    if (activeStep === 4) {
        const { whyUs, usBased, termsAgreed, participationAgreed } = formData;
        if (!whyUs) {
            newErrors.push({ field: 'whyUs', errorText: 'Please tell why you should be accepted onto the program' });
        }
        if (!usBased) {
            newErrors.push({ field: 'usBased', errorText: 'Please confirm that you are based in the USA' });
        }
        if (!termsAgreed) {
            newErrors.push({ field: 'termsAgreed', errorText: 'Please agree to our Terms and Conditions' });
        }
        if (!participationAgreed) {
            newErrors.push({ field: 'participationAgreed', errorText: 'Please confirm that you are free to participate in the program' });
        }

        return newErrors;
    }

    return newErrors;
};

const interestValidation = ({ formData }) => {
    const newErrors = [];
    const { givenName, familyName, email, companyName } = formData;
    if (!givenName) {
        newErrors.push({ field: 'givenName', errorText: 'You must enter a first name' });
    }
    if (!familyName) {
        newErrors.push({ field: 'familyName', errorText: 'You must enter your last/family name' });
    }
    if (!email) {
        newErrors.push({ field: 'email', errorText: 'Please enter an email for us to confirm your application' });
    } else if (!validateEmail(email)) {
        newErrors.push({ field: 'email', errorText: 'Please enter a valid email address' });
    }
    if (!companyName) {
        newErrors.push({ field: 'companyName', errorText: 'Please let us know the name of your company' });
    }

    return newErrors;
};

export { formValidation, interestValidation };
