// export const API_BASE_URL = 'https://deo66wmc7c.execute-api.us-east-1.amazonaws.com/';
// export const API_BASE_URL = 'https://ox9yp0dlja.execute-api.eu-west-2.amazonaws.com/';

// export const AWS_API = {
//     poolId: 'us-east-1_uopEyXlMp',
//     appClientId: '7q3i8jt0jofcj0k028t16mt1p7'
// };
// export const AWS_API = {
//     poolId: 'eu-west-2_31wNFaWI3',
//     appClientId: '24glr7r0krn8j4vedhvla49351'
// };
import bennyBW from 'assets/images/team/bennyBW.jpg';
import bruceHBW from 'assets/images/team/bruceHBW.jpg';
import bruceKBW from 'assets/images/team/bruceKBW.jpg';
import cindyBW from 'assets/images/team/cindyBW.jpg';
import endriBW from 'assets/images/team/endriBW.jpg';
import gracielaBW from 'assets/images/team/gracielaBW.jpg';
import janeBW from 'assets/images/team/janeBW.jpg';
import michaelBW from 'assets/images/team/michaelBW.jpg';
import simonBW from 'assets/images/team/simonBW.jpg';
import taherBW from 'assets/images/team/taherBW.jpg';
import vijayBW from 'assets/images/team/vijayBW.jpg';

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
export const BASE_PATH = '';

export const ADMIN_DASHBOARD_PATH = '/admin';
export const FOUNDER_DASHBOARD_PATH = '/founder';
export const MENTOR_DASHBOARD_PATH = '/mentor';

const config = {
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    // presetColor: 'default', // default, theme1, theme2, theme3, theme4, theme5, theme6
    presetColor: 'theme6',
    locale: 'en', // 'en' - English, 'fr' - French, 'ro' - Romanian, 'zh' - Chinese
    container: false
};

export const userTypes = [
    { id: 'admin', name: 'Administrator' },
    { id: 'mentor', name: 'Mentor' },
    { id: 'founder', name: 'Founder' }
];

export const programStatus = [
    { id: 'pending', name: 'Pending' },
    { id: 'approved', name: 'Approved for Lauch' },
    { id: 'active', name: 'Open' },
    { id: 'closed', name: 'Closed For Applications' },
    { id: 'completed', name: 'Completed' }
];

export const scoreStatus = [
    { id: 'unscored', name: 'Not Yet Scored', color: 'secondary' },
    { id: 'scored', name: 'Not Yet Submitted', color: 'orange' },
    { id: 'submitted', name: 'Scores Submitted', color: 'success' },
    { id: 'final', name: 'Scores Finalized', color: 'success' }
];

export const myApplicationStatus = [
    { id: '0', name: 'Reviewed', shortName: 'Reviewed', color: 'success' },
    { id: '1', name: 'Awaiting Review', shortName: 'Awaiting', color: 'secondary' }
];

export const rejectedStatus = ['1', '3', '5', '9'];

export const applicationStatus = [
    {
        id: '0',
        text: 'Submitted',
        shortText: 'Submitted',
        color: 'orange',
        explainer: 'Your application has been submitted but not yet reviewed.'
    },
    {
        id: '1',
        text: 'Screening: Rejected',
        shortText: 'Rejected:1',
        color: 'error',
        explainer: 'The application has failed the initial screening and the founder has been sent a rejection email'
    },
    {
        id: '2',
        text: 'Awaiting Review',
        shortText: 'Reviewing',
        color: 'information',
        explainer: 'The application is being reviewed by the m+a team'
    },
    {
        id: '3',
        text: 'Review Rejected',
        shortText: 'Rejected:2',
        color: 'error',
        explainer: 'The application was reviewed by the m+a team but failed to make the shortlist'
    },
    {
        id: '4',
        text: 'Shortlisted',
        shortText: 'Shortlisted',
        color: 'information',
        explainer: 'The application was reviewed by the m+a team and has been shortlisted'
    },
    {
        id: '5',
        text: 'Interview Rejected',
        shortText: 'Rejected:3',
        color: 'error',
        explainer: 'The founder was interviewed and was not selected to attend the program'
    },
    {
        id: '6',
        text: 'Accepted into Program',
        shortText: 'Accepted',
        color: 'success',
        explainer: 'The founder was interviewed and has been selected to attend the program'
    },
    {
        id: '7',
        text: 'Waitlisted',
        shortText: 'Waitlisted',
        color: 'warning',
        explainer: 'The founder was interviewed and has been waitlisted'
    },
    {
        id: '8',
        text: 'Confirmed',
        shortText: 'Confirmed',
        color: 'success',
        explainer: 'The founder was interviewed and has been selected to attend the program'
    },
    {
        id: '9',
        text: 'Declined',
        shortText: 'Declined',
        color: 'success',
        explainer: 'The founder was offered a place on the program but has declined to attend'
    },
    {
        id: '99',
        text: 'Error',
        shortText: 'Error',
        color: 'error',
        explainer: 'The status is in error'
    }
];

export const countryList = [
    { id: 'AF', name: 'Afghanistan' },
    { id: 'AX', name: 'Åland Islands' },
    { id: 'AL', name: 'Albania' },
    { id: 'DZ', name: 'Algeria' },
    { id: 'AS', name: 'American Samoa' },
    { id: 'AD', name: 'Andorra' },
    { id: 'AO', name: 'Angola' },
    { id: 'AI', name: 'Anguilla' },
    { id: 'AQ', name: 'Antarctica' },
    { id: 'AG', name: 'Antigua and Barbuda' },
    { id: 'AR', name: 'Argentina' },
    { id: 'AM', name: 'Armenia' },
    { id: 'AW', name: 'Aruba' },
    { id: 'AU', name: 'Australia' },
    { id: 'AT', name: 'Austria' },
    { id: 'AZ', name: 'Azerbaijan' },
    { id: 'BS', name: 'Bahamas', includeThe: true },
    { id: 'BH', name: 'Bahrain' },
    { id: 'BD', name: 'Bangladesh' },
    { id: 'BB', name: 'Barbados' },
    { id: 'BY', name: 'Belarus' },
    { id: 'BE', name: 'Belgium' },
    { id: 'BZ', name: 'Belize' },
    { id: 'BJ', name: 'Benin' },
    { id: 'BM', name: 'Bermuda' },
    { id: 'BT', name: 'Bhutan' },
    { id: 'BO', name: 'Bolivia, Plurinational State of' },
    { id: 'BQ', name: 'Bonaire, Sint Eustatius and Saba' },
    { id: 'BA', name: 'Bosnia and Herzegovina' },
    { id: 'BW', name: 'Botswana' },
    { id: 'BV', name: 'Bouvet Island' },
    { id: 'BR', name: 'Brazil' },
    { id: 'IO', name: 'British Indian Ocean Territory', includeThe: true },
    { id: 'BN', name: 'Brunei Darussalam' },
    { id: 'BG', name: 'Bulgaria' },
    { id: 'BF', name: 'Burkina Faso' },
    { id: 'BI', name: 'Burundi' },
    { id: 'CV', name: 'Cabo Verde' },
    { id: 'KH', name: 'Cambodia' },
    { id: 'CM', name: 'Cameroon' },
    { id: 'CA', name: 'Canada' },
    { id: 'KY', name: 'Cayman Islands', includeThe: true },
    { id: 'CF', name: 'Central African Republic', includeThe: true },
    { id: 'TD', name: 'Chad' },
    { id: 'CL', name: 'Chile' },
    { id: 'CN', name: 'China' },
    { id: 'CX', name: 'Christmas Island' },
    { id: 'CC', name: 'Cocos (Keeling) Islands', includeThe: true },
    { id: 'CO', name: 'Colombia' },
    { id: 'KM', name: 'Comoros' },
    { id: 'CD', name: 'Congo, Democratic Republic of the' },
    { id: 'CG', name: 'Congo' },
    { id: 'CK', name: 'Cook Islands', includeThe: true },
    { id: 'CR', name: 'Costa Rica' },
    { id: 'CI', name: "Côte d'Ivoire" },
    { id: 'HR', name: 'Croatia' },
    { id: 'CU', name: 'Cuba' },
    { id: 'CW', name: 'Curaçao' },
    { id: 'CY', name: 'Cyprus' },
    { id: 'CZ', name: 'Czechia' },
    { id: 'DK', name: 'Denmark' },
    { id: 'DJ', name: 'Djibouti' },
    { id: 'DM', name: 'Dominica' },
    { id: 'DO', name: 'Dominican Republic', includeThe: true },
    { id: 'EC', name: 'Ecuador' },
    { id: 'EG', name: 'Egypt' },
    { id: 'SV', name: 'El Salvador' },
    { id: 'GQ', name: 'Equatorial Guinea' },
    { id: 'ER', name: 'Eritrea' },
    { id: 'EE', name: 'Estonia' },
    { id: 'SZ', name: 'Eswatini' },
    { id: 'ET', name: 'Ethiopia' },
    { id: 'FK', name: 'Falkland Islands (Malvinas)', includeThe: true },
    { id: 'FO', name: 'Faroe Islands', includeThe: true },
    { id: 'FJ', name: 'Fiji' },
    { id: 'FI', name: 'Finland' },
    { id: 'FR', name: 'France' },
    { id: 'GF', name: 'French Guiana' },
    { id: 'PF', name: 'French Polynesia' },
    { id: 'TF', name: 'French Southern Territories' },
    { id: 'GA', name: 'Gabon' },
    { id: 'GM', name: 'Gambia', includeThe: true },
    { id: 'GE', name: 'Georgia' },
    { id: 'DE', name: 'Germany' },
    { id: 'GH', name: 'Ghana' },
    { id: 'GI', name: 'Gibraltar' },
    { id: 'GR', name: 'Greece' },
    { id: 'GL', name: 'Greenland' },
    { id: 'GD', name: 'Grenada' },
    { id: 'GP', name: 'Guadeloupe' },
    { id: 'GU', name: 'Guam' },
    { id: 'GT', name: 'Guatemala' },
    { id: 'GG', name: 'Guernsey' },
    { id: 'GW', name: 'Guinea-Bissau' },
    { id: 'GN', name: 'Guinea' },
    { id: 'GY', name: 'Guyana' },
    { id: 'HT', name: 'Haiti' },
    { id: 'HM', name: 'Heard Island and McDonald Islands' },
    { id: 'VA', name: 'Holy See' },
    { id: 'HN', name: 'Honduras' },
    { id: 'HK', name: 'Hong Kong' },
    { id: 'HU', name: 'Hungary' },
    { id: 'IS', name: 'Iceland' },
    { id: 'IN', name: 'India' },
    { id: 'ID', name: 'Indonesia' },
    { id: 'IR', name: 'Iran, Islamic Republic of' },
    { id: 'IQ', name: 'Iraq' },
    { id: 'IE', name: 'Ireland' },
    { id: 'IM', name: 'Isle of Man', includeThe: true },
    { id: 'IL', name: 'Israel' },
    { id: 'IT', name: 'Italy' },
    { id: 'JM', name: 'Jamaica' },
    { id: 'JP', name: 'Japan' },
    { id: 'JE', name: 'Jersey' },
    { id: 'JO', name: 'Jordan' },
    { id: 'KZ', name: 'Kazakhstan' },
    { id: 'KE', name: 'Kenya' },
    { id: 'KI', name: 'Kiribati' },
    { id: 'KP', name: "Korea, Democratic People's Republic of" },
    { id: 'KR', name: 'Korea, Republic of' },
    { id: 'KW', name: 'Kuwait' },
    { id: 'KG', name: 'Kyrgyzstan' },
    { id: 'LA', name: "Lao People's Democratic Republic" },
    { id: 'LV', name: 'Latvia' },
    { id: 'LB', name: 'Lebanon' },
    { id: 'LS', name: 'Lesotho' },
    { id: 'LR', name: 'Liberia' },
    { id: 'LY', name: 'Libya' },
    { id: 'LI', name: 'Liechtenstein' },
    { id: 'LT', name: 'Lithuania' },
    { id: 'LU', name: 'Luxembourg' },
    { id: 'MO', name: 'Macao' },
    { id: 'MG', name: 'Madagascar' },
    { id: 'MW', name: 'Malawi' },
    { id: 'MY', name: 'Malaysia' },
    { id: 'MV', name: 'Maldives', includeThe: true },
    { id: 'ML', name: 'Mali' },
    { id: 'MT', name: 'Malta' },
    { id: 'MH', name: 'Marshall Islands', includeThe: true },
    { id: 'MQ', name: 'Martinique' },
    { id: 'MR', name: 'Mauritania' },
    { id: 'MU', name: 'Mauritius' },
    { id: 'YT', name: 'Mayotte' },
    { id: 'MX', name: 'Mexico' },
    { id: 'FM', name: 'Micronesia, Federated States of' },
    { id: 'MD', name: 'Moldova, Republic of' },
    { id: 'MC', name: 'Monaco' },
    { id: 'MN', name: 'Mongolia' },
    { id: 'ME', name: 'Montenegro' },
    { id: 'MS', name: 'Montserrat' },
    { id: 'MA', name: 'Morocco' },
    { id: 'MZ', name: 'Mozambique' },
    { id: 'MM', name: 'Myanmar' },
    { id: 'NA', name: 'Namibia' },
    { id: 'NR', name: 'Nauru' },
    { id: 'NP', name: 'Nepal' },
    { id: 'NL', name: 'Netherlands', includeThe: true },
    { id: 'NC', name: 'New Caledonia' },
    { id: 'NZ', name: 'New Zealand' },
    { id: 'NI', name: 'Nicaragua' },
    { id: 'NE', name: 'Niger' },
    { id: 'NG', name: 'Nigeria' },
    { id: 'NU', name: 'Niue' },
    { id: 'NF', name: 'Norfolk Island' },
    { id: 'MK', name: 'North Macedonia' },
    { id: 'MP', name: 'Northern Mariana Islands' },
    { id: 'NO', name: 'Norway' },
    { id: 'OM', name: 'Oman' },
    { id: 'PK', name: 'Pakistan' },
    { id: 'PW', name: 'Palau' },
    { id: 'PS', name: 'Palestine, State of' },
    { id: 'PA', name: 'Panama' },
    { id: 'PG', name: 'Papua New Guinea' },
    { id: 'PY', name: 'Paraguay' },
    { id: 'PE', name: 'Peru' },
    { id: 'PH', name: 'Philippines', includeThe: true },
    { id: 'PN', name: 'Pitcairn' },
    { id: 'PL', name: 'Poland' },
    { id: 'PT', name: 'Portugal' },
    { id: 'PR', name: 'Puerto Rico' },
    { id: 'QA', name: 'Qatar' },
    { id: 'RE', name: 'Réunion' },
    { id: 'RO', name: 'Romania' },
    { id: 'RU', name: 'Russian Federation', includeThe: true },
    { id: 'RW', name: 'Rwanda' },
    { id: 'BL', name: 'Saint Barthélemy' },
    { id: 'SH', name: 'Saint Helena, Ascension and Tristan da Cunha' },
    { id: 'KN', name: 'Saint Kitts and Nevis' },
    { id: 'LC', name: 'Saint Lucia' },
    { id: 'MF', name: 'Saint Martin, (French part)' },
    { id: 'PM', name: 'Saint Pierre and Miquelon' },
    { id: 'VC', name: 'Saint Vincent and the Grenadines' },
    { id: 'WS', name: 'Samoa' },
    { id: 'SM', name: 'San Marino' },
    { id: 'ST', name: 'Sao Tome and Principe' },
    { id: 'SA', name: 'Saudi Arabia' },
    { id: 'SN', name: 'Senegal' },
    { id: 'RS', name: 'Serbia' },
    { id: 'SC', name: 'Seychelles', includeThe: true },
    { id: 'SL', name: 'Sierra Leone' },
    { id: 'SG', name: 'Singapore' },
    { id: 'SX', name: 'Sint Maarten, (Dutch part)' },
    { id: 'SK', name: 'Slovakia' },
    { id: 'SI', name: 'Slovenia' },
    { id: 'SB', name: 'Solomon Islands', includeThe: true },
    { id: 'SO', name: 'Somalia' },
    { id: 'ZA', name: 'South Africa' },
    { id: 'GS', name: 'South Georgia and the South Sandwich Islands' },
    { id: 'SS', name: 'South Sudan' },
    { id: 'ES', name: 'Spain' },
    { id: 'LK', name: 'Sri Lanka' },
    { id: 'SD', name: 'Sudan' },
    { id: 'SR', name: 'Suriname' },
    { id: 'SJ', name: 'Svalbard and Jan Mayen' },
    { id: 'SE', name: 'Sweden' },
    { id: 'CH', name: 'Switzerland' },
    { id: 'SY', name: 'Syrian Arab Republic', includeThe: true },
    { id: 'TW', name: 'Taiwan, Province of China' },
    { id: 'TJ', name: 'Tajikistan' },
    { id: 'TZ', name: 'Tanzania, United Republic of' },
    { id: 'TH', name: 'Thailand' },
    { id: 'TL', name: 'Timor-Leste' },
    { id: 'TG', name: 'Togo' },
    { id: 'TK', name: 'Tokelau' },
    { id: 'TO', name: 'Tonga' },
    { id: 'TT', name: 'Trinidad and Tobago' },
    { id: 'TN', name: 'Tunisia' },
    { id: 'TR', name: 'Turkey' },
    { id: 'TM', name: 'Turkmenistan' },
    { id: 'TC', name: 'Turks and Caicos Islands', includeThe: true },
    { id: 'TV', name: 'Tuvalu' },
    { id: 'UG', name: 'Uganda' },
    { id: 'UA', name: 'Ukraine' },
    { id: 'AE', name: 'United Arab Emirates', includeThe: true },
    { id: 'GB', name: 'United Kingdom of Great Britain and Northern Ireland', includeThe: true },
    { id: 'US', name: 'United States of America', includeThe: true },
    { id: 'UM', name: 'United States Minor Outlying Islands', includeThe: true },
    { id: 'UY', name: 'Uruguay' },
    { id: 'UZ', name: 'Uzbekistan' },
    { id: 'VU', name: 'Vanuatu' },
    { id: 'VE', name: 'Venezuela, Bolivarian Republic of' },
    { id: 'VN', name: 'Viet Nam' },
    { id: 'VG', name: 'British Virgin Islands', includeThe: true },
    { id: 'VI', name: 'U.S. Virgin Islands', includeThe: true },
    { id: 'WF', name: 'Wallis and Futuna' },
    { id: 'EH', name: 'Western Sahara', includeThe: true },
    { id: 'YE', name: 'Yemen' },
    { id: 'ZM', name: 'Zambia' },
    { id: 'ZW', name: 'Zimbabwe' }
];

export const industryList = [
    'Advertising',
    'Agriculture',
    'Business Services',
    'Construction',
    'Consumer Products',
    'Consumer Services',
    'Education',
    'Electronics/High Tech',
    'Energy',
    'Fashion',
    'Financial Services',
    'Food & Beverage',
    'Gaming',
    'Healthcare',
    'Life Sciences',
    'Manufacturing',
    'Media/Entertainment',
    'Professional Services',
    'Real Estate',
    'Retail Tech',
    'Software/IT',
    'Sports',
    'Transportation',
    'Travel & Leisure',
    'Other'
];

export const busTechList = [
    'AI',
    'B2B',
    'B2B SaaS',
    'Betting/Gaming',
    'Consumer Products',
    'Data/Cyber-Security',
    'Digital Media',
    'DTC Products',
    'DTC Services',
    'eCommerce',
    'Frontier Tech',
    'Hardware/IOT',
    'HR Tech',
    'Marketing Tech',
    'Marketplace',
    'Med Tech',
    'Software/IT',
    'Sports Tech',
    'Other'
];

export const legalStructureList = ['LLC', 'C Corporation', 'S Corporation', 'Nonprofit', 'Sole Proprietorship', 'Not yet set up'];
export const stageList = ['Concept', 'MVP Created', 'Beta Product', 'Product in Market'];
export const tractionList = ['Pre-Revenue', 'Pilot Revenue', '>$1k MRR', '>$10k MRR'];
export const addressableMarketList = ['Niche Only', 'Unknown', '<$10M', '<$100M', '<$1B', '>$1B'];

export const team = [
    {
        title: 'The Energizer',
        avatar: {
            src: gracielaBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/GracielaBW.jpg'
        },
        name: 'Graciela Bergner',
        linkedIn: 'https://www.linkedIn.com/in/gracielabergner/',
        bio: [
            'Graciela is the principal of a multi-family office established in NYC. Graciela is also an angel investor, crypto investor and an advisor and mentor to early entrepreneurs building tech-enabled platforms in web2 and web3.',
            'Prior to establishing her own multi-family office, Graciela worked as an international financial advisor in the Global Wealth Management division at Merrill Lynch in New York. Before moving to New York, Graciela spent most of her professional career in Argentina, where she was born and raised.',
            'In Argentina, Graciela was the chief commercial and chief marketing officer of La Nacion S.A., the largest newspaper of record in the country, and Newsan S.A., the largest manufacturer, marketer and distributor of consumer electronics and home appliances in Argentina. Graciela was a member of the Executive Board at both La Nacion and Newsan.',
            'Graciela holds a J.D. from Columbia Law School, and is a Licentiate in Business Administration, cum laude, from Universidad de Buenos Aires, Argentina.'
        ]
    },
    {
        title: 'Gamer',
        avatar: {
            src: bruceHBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/BruceHBW.jpg'
        },
        name: 'Bruce Hack',
        linkedIn: 'http://www.linkedIn.com/in/brucehack',
        bio: [
            'Bruce Hack is founder and CEO of BLH Venture, LLC, a provider of consulting and investment services to media and entertainment companies. He is a director or advisor with numerous early/mid stage firms and VC funds in those sectors.',
            "Mr. Hack was Director then Chairman of Technicolor, Inc (TCH), the world's largest creative services and second largest set-top box and internet gateway business, from 2010-19. Prior, he was a principal in the creation of Activision Blizzard, the largest video game company in the world, and served as its Executive Vice Chairman from 2008. From 2004-2008, he was CEO of Vivendi Games, the developer and operator of numerous franchises including Blizzard’s World of Warcraft, the world’s largest subscription-based online game. Previously, Mr. Hack was Executive Vice Chairman, Universal Music Group, the world’s largest music company, from 1998 to 2001, and CFO, Universal Studios, from 1995 to 1998. He joined the Seagram Company Ltd. in 1982 after serving as an international trade negotiator at the U.S. Treasury in Washington, D.C. Amongst his roles at Seagram were CFO of Tropicana Products, Inc. and Director, Strategic Planning, at The Seagram Company Ltd.",
            'Mr. Hack has guest lectured on leadership and entrepreneurship at Stanford, Princeton, Yale, Cornell and UCLA. He is a Director at Overwolf, Thrive Fantasy, Surf Air and Games for Change, member of Endeavor Global, and on advisory councils for the Hamilton Lugar School for global and international studies at Indiana University and the Cornell University A&S school. He earned a B.A. from Cornell and an M.B.A from the University of Chicago.'
        ]
    },
    {
        title: 'Marketing Wiz',
        avatar: {
            src: cindyBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/CindyBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/cindy-cook/',
        name: 'Cindy Cook',
        bio: [
            'Cindy Cook is an accomplished business leader and CMO whose career has focused on transforming consumer experiences in video games, music, education, and healthcare by leveraging technology. She drove digital transformation and the creation of new business models at industry leading firms in each of those sectors.',
            'In video games, Cindy helped launch Blizzard’s World of Warcraft, the defining online game of the 2000’s, while at Vivendi Games. She established, built, and led two divisions that developed and published mobile and online casual games. Cindy also ran the company’s marketing for leading PC and console titles.',
            'In music, Cindy was part of the team at Universal Music Group that launched the first commercial digital music services, both download and streaming.',
            'In education, she led marketing at Cengage Learning, helping launch their innovative, personalized digital platform for higher education publishing. She introduced a metric-driven framework for KPIs and automation software.',
            'In healthcare, she headed marketing for Elsevier’s healthcare and education B2B and B2C businesses. She created brand architecture for the product suite of disparate brands and refocused marketing efforts on thought leadership and sales effectiveness.',
            'Early in her career, Cindy worked in consumer products at Pepsi and Seagram. At Pepsi she led the largest restaurant partner promotion at the time and repositioned one of the company’s major soft drink brands. At Seagram she launched several new products in the low alcohol refreshment category.',
            'Cindy is currently a Board Member at New York Angels, a Board Observer at Allstar, technology for the creator economy in video games, and Kidas, security software for parents monitoring their children’s video game playing. She is a member of Mentors + Angels, a group dedicated to helping under-represented founders succeed.'
        ]
    },
    {
        title: 'Legacy Media Guy Gone Digital',
        avatar: {
            src: michaelBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/MichaelBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/michael-r-costa/',
        name: 'Michael Costa',
        bio: [
            'Michael is a “legacy media guy gone digital.” With experience in media, digital transformations, M&A and governance, he has a proven track record as a Strategic Advisor, Board Member and Start-Up Investor/Mentor. As an investment banker for 25 years, he served as a Financial Advisor to multiple board of directors in the technology, media and telecommunications industries and successfully directed the mergers, acquisitions, and corporate restructurings for globally recognized businesses on transformative transactions totaling more than $300B.',
            'During a nearly 10-year tenure as a key independent director for Scripps Networks Interactive, he fostered corporate strategies toward the achievement of significant milestones for this publicly traded lifestyle media company with leading brands HGTV, the Food Network and the Travel Channel.  Following the spin-off from its legacy newspaper parent to its acquisition by Discovery 10 years later, he oversaw the creation of more than $6B in value.',
            "Over the last five years he sourced, cultivated, and mentored over 50 startups in the digital media, SaaS, gaming, and entertainment sectors resulting in several investments with an average capital raise of $1.5mm and a pre-money valuation of $8mm. He is quite active in the NYC Metro area ecosystem for entrepreneurs as a board member of New York Angels, a lecturer in SUNY Purchase's Entrepreneurship in the Arts MA program and an Innovation Fellow at The Eugene Lang Entrepreneurship Center at Columbia Business School."
        ]
    },
    {
        title: 'Healthcare Guru',
        avatar: {
            src: vijayBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/VijayBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/vijay-aggarwal-bb11711',
        name: 'Vijay Aggarwal',
        bio: [
            'Vijay has over thirty years of senior management experience in pharmaceutical services, medical devices, and clinical diagnostics. He is currently Managing Partner of The Channel Group, LLC, a life sciences venture development, and management firm. He is also an active investor with the New York Angels and the Broad Street Angels.',
            'Most recently, Dr. Aggarwal was President and CEO of Aureon Laboratories, Inc., a predictive pathology company offering advanced tissue analysis services to practicing physicians and the pharmaceutical industry. Previously, Dr. Aggarwal was CEO of Vaxigenix, a developer of immune treatments in oncology, CEO of InstantDx, one of the first e-prescription services, President of AAI Development Services, a global contract research organization and President of Quest Diagnostic Ventures, where he had responsibility for new technology, new business models, clinical trials testing, and direct–to-consumer strategies.',
            'Earlier in his career, Dr. Aggarwal held many positions with SmithKline Beecham Clinical Laboratories, including direct responsibility for all SBCL’s U.S.-based laboratories as Executive Vice President of Laboratories, for reimbursement as Vice President of Managed Care and as a Vice President in the Healthcare Services division of SmithKline Beecham.',
            'As an active angel investor and advisor to venture capital organizations and as a CEO of venture backed companies, he has extensive experience in all aspects of early-stage company funding efforts.',
            'He serves on the Boards of Accugenomics, Allergenis, Interpace Biosciences, Medcura and Moleculera and is an advisor to Slone Partners. Dr. Aggarwal serves on the Boards of New York Angels and Broad Street Angels and is a member of m+a.',
            'He earned a BA in Chemistry from Case Western Reserve University and a Ph.D. in Pharmacology/Toxicology from the Medical College of Virginia.'
        ]
    },
    {
        title: 'Championing Women & Minorities',
        avatar: {
            src: bennyBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/VijayBW.jpg'
        },
        linkedIn: 'https://www.linkedin.com/in/bennylorenzo/',
        name: 'Benny Lorenzo',
        bio: [
            'Benny Lorenzo is an accomplished business leader and investor with a remarkable track record in the financial industry. Since 1996, he has been the managing partner of Aspira Capital Management, a financial institution and early-stage venture capital firm that invests in exceptional Black, Latine and female founders, among underrepresented entrepreneurs. With a diverse portfolio spanning financial technology, artificial intelligence, software, e-commerce, and healthcare, Benny has invested in over 50 companies over the last 15 years across Series Seed, A, B, and C+ rounds, including two unicorns. Benny is also a member of the New York Angels and the Harvard Business School Alumni Angels.',
            'Prior to founding Aspira, Benny was the portfolio manager and general partner of P.A.W. Capital Partners, L.P., a hedge fund that grew from $35M to $350M during his tenure. He was the chairman and CEO of Kaufman Bros, L.P., an investment bank, and securities broker/dealer focused on the technology, media, and telecommunications sectors. He has spent more than 30 years developing his expertise in financial services and technology industries as an analyst, Director of Research, investment banker, portfolio manager, angel investor, and venture capitalist. He was also Product Manager of Workstation Products with IBM and an Engineer with AT&T.',
            "Benny is committed to community service and volunteers with organizations including the City University of New York's Dominican Studies Institute and the Washington Heights and Inwood Chamber of Commerce. In recognition of his contributions, Benny received the Citizenship Order of Merit from the Government of the Dominican Republic, an “El Program 2010” from El Diario La Prensa for distinguished contributions to the Hispanic community, and a Proclamation of the Council of the City of New York for providing accountable leadership as Board Secretary of the Dominican Day Parade, Inc. in 2015, an annual cultural event drawing over 500,000 participants.",
            'Born in the Dominican Republic, Benny overcame obstacles to earn a BS in Industrial Engineering and Operations Research from Cornell University and an MBA from Harvard Business School.'
        ]
    },
    // {
    //     title: 'Championing Female Founders',
    //     avatar: {
    //         src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/KristenBW.jpg'
    //     },
    //     linkedIn: 'https://www.linkedIn.com/in/kristen-dickey/',
    //     name: 'Kristen Dickey'
    // },
    {
        title: 'Kitchen Gadget Guru',
        avatar: {
            src: janeBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/Jane.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/jane-och-05414a87/',
        name: 'Jane Och',
        bio: [
            'Jane Och began her investment career in 1986 at Goldman Sachs Group, Inc. as an Equity Sales Specialist and then as an analyst at Tiger Management.  In 2011, she and her husband Daniel S. Och co-founded Willoughby Capital Holdings, LLC, a private investment firm managing the assets of the Och family and the Jane and Daniel Och Foundation.',
            'In 2012, she founded Guac-Lock, LLC, a consumer-goods company which oversees the development, manufacturing, marketing and sales of the Guac-Lock, a patented container that prevents guacamole from turning brown (which was co-invented by her). Jane is also a co-founder and co-inventor of The Inside Scoop, a kitchen utensil designed to conveniently scoop out the inside of a bagel or bread. She thrives on identifying and nurturing new ideas and talent through innovation and mentoring.',
            'She holds a Bachelors in Business and a Master of Accounting from the University of Michigan Ross School.'
        ]
    },
    {
        title: 'Our Go-To Tech Guy',
        avatar: {
            src: taherBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/TaherBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/taher-baderkhan-2b4b363/',
        name: 'Taher Baderkhan',
        bio: [
            'Taher Baderkhan is an Entrepreneur, Executive and Tech Advisor with experience in start-ups and scale-ups. He is passionate about designing, building, and launching innovative products that drive business outcomes. Taher served as a Co-founder and CTO at YouVisit, an immersive media company in the education Martech space. He has deep expertise in cloud architecture, data analytics, product and user experience design, 3D media and VR technology. Prior to YouVisit, Taher worked at Accenture building complex trading applications for financial institutions. He has several patents in VR space specific to 3D video projection and compression.',
            'Taher built YouVisit into a successful profitable business generating 8-digit annual revenue without raising any outside funding. YouVisit was acquired in 2019 by EAB.',
            'Taher is a founding member of the YouVisit Foundation whose mission is to inspire, motivate, and help thousands of students from all backgrounds find the post-secondary option that is right for them.',
            'Taher has a BA in Computer Science and Economics from Brandeis University.'
        ]
    },
    // {
    //     title: 'A Classical Angel',
    //     avatar: {
    //         src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/EugeneBW.jpg'
    //     },
    //     linkedIn: 'https://www.linkedIn.com/in/eugenecarr/',
    //     name: 'Eugene Carr'
    // },
    {
        title: 'Latent Developer',
        avatar: {
            src: simonBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/SimonBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/simonhopkins1/',
        name: 'Simon Hopkins',
        bio: [
            'Simon describes himself as an entrepreneur, investor, and part-time coder. Working primarily within the IT sector, he has a track record of starting, acquiring, and growing organizations.',
            'An Electrical Engineering graduate from Imperial College, London, he started his career with Andersen Consulting (now Accenture). Three years later, Simon joined a start-up that went on to IPO in the mid-90s. Soon afterwards Simon opened offices for the newly listed company in Malaysia and Singapore, growing the business to over 100 staff and $12m in revenue within 5 years.',
            'He was then handed the challenge of running a post dot.com business in the US. Rather than let the business fail, Simon led an MBO, and sold the new venture 18 months later. Under the new ownership, as COO, he grew the business to over $200m in revenue.',
            'More recently, Simon was responsible for the acquisition of an HR Tech business and its integration into a U.K. parent company. He subsequently set up the US subsidiary of a U.K. cloud-migration company, growing revenue to $3m.',
            'Simon now works primarily as an Angel Investor and mentor, where he has made investments in over 40 companies. Over the past ten years, he has taken several short-term operational roles (as COO, CFO and CTO) in his portfolio companies. He has also served as a mentor at Princeton University’s eLab, SOSV’s dLab and as part of NSF’s iCorp program. Currently Simon is a Board Member of the New York Angels and lives in Princeton, NJ with his wife and 3 children.'
        ]
    },
    {
        title: 'Consumer Goods King',
        avatar: {
            src: bruceKBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/BruceKBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/brucekaminstein/',
        name: 'Bruce Kaminstein',
        bio: [
            'Bruce is the founder of Casabella, a global housewares product company. He has served as President until selling the company in 2017 to Bradshaw International, a leading housewares company, and became a managing director. In 2020 Bruce retired from Bradshaw International and joined the New York Angels as a member to invest and mentor start-ups. He is also a member of Mentors and Angels, focusing on underrepresented entrepreneurs.',
            'Bruce is a past Chairman of the Board of the International Housewares Association, an organization that represents the $85 Billion housewares industry. He currently serves on the Board of Brooklyn Boatworks, a non-profit serving NYC schools teaching students in afterschool programs to learn how to make boats using tools and teamwork.',
            'He has advised CEO’s of housewares companies on business strategy, product innovation, scaling operations, succession planning, among other topics.',
            'Bruce holds a BA in Criminal Justice/Sociology with a minor in Economics from Alfred University.'
        ]
    },
    {
        title: 'Educating Us In EdTech',
        avatar: {
            src: endriBW
            // src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/EndriBW.jpg'
        },
        linkedIn: 'https://www.linkedIn.com/in/endritolka/',
        name: 'Endri Tolka',
        bio: [
            'Endri Tolka is a commercially minded and outcome-driven Entrepreneur, Executive and Board Member with experience in start-ups, scale-ups and M&A. Endri served as Co-founder, Board Member, CFO/COO at YouVisit, where he built a proprietary SaaS-based technology platform to enable deep user engagement and drive conversions through highly interactive 360° experiences across mobile, web and VR/AR.',
            'During his tenure, he built and scaled different Go-to-Market functions (on-boarding, Client Success) and operational functions (Accounting, Finance, People Ops and Legal) while leading a team of 55.',
            "Endri speaks 4 languages and holds an MBA in Finance from New York University Stern School of Business and a B.A in Computer Science and Economics from Brandeis University. Endri is a member of the New York Angels where he invests in early-stage software companies. Endri is a mentor for Endeavor where he helps companies from around the world with operational and financial strategy. He's also a coach for the NYU Stern Business Plan Competition."
        ]
    },
    {
        title: 'Our VIP for IP',
        avatar: {
            src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/RayBW.jpg'
        },
        linkedIn: 'https://www.linkedin.com/in/raymondfarrell/',
        name: 'Ray Farrell',
        bio: [
            'An endorphin rush for Ray is meeting with founders from around the world and seeing how he can connect them with key contacts and resources to support their efforts and help them gain experience to succeed.',
            'Ray’s journey so far has led him to follow the path into Intellectual Property law by earning an undergraduate degree in mechanical engineering and attending law school.  He has over 30 years’ experience in IP law.  For the past 15 years, Ray has particularly focused in key areas where he feels he can be most effective to help improve some of the ecosystems supporting startups, namely: global intellectual property law harmonization, venture mentoring and angel investing.',
            'Ray is part of a small group of international IP professionals representing industry that have been engaged in efforts to harmonize patent laws among the major patent offices, i.e., the US, Europe, Japan, China and Korea, which issue about 90% of all the world’s patents.  That group also meets with the heads of the noted patent offices and their international teams to provide key input.',
            'He also was a driving force to create a venture mentoring service through MIT’s Venture Mentoring Service Outreach Program.  That program included a consortium of NY regional institutions, e.g., Cold Spring Harbor Laboratory, Brookhaven National Laboratory, The Feinstein Institute, Hofstra University and StonyBrook University.',
            'For over 10 years, Ray was on the advisory board of the Clean Energy Incubator Program under the auspices of the Long Island High Tech Incubator.  It was through those forays into working more directly with startups that Ray first became an angel investor, and which ultimately led him to become a member of the New York Angels.'
        ]
    },
    {
        title: 'Life Science Trouble Seeker',
        avatar: {
            src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/JohnBW.jpg'
        },
        linkedIn: 'https://www.linkedin.com/in/jgyounger/',
        name: 'John Younger',
        bio: [
            "I left a 20-year career in academic medicine to close the seed round on my first company, and never looked back.  I've been a founder, a board chair, and an investor.  I was the technical founder at Akadeum Life Sciences, a research tool and material science company for basic science applications and cell therapy.  Most recently I was Chair and CEO of New View Surgical, a clinical-stage laparoscopic device company.",
            'I love new healthcare, life science, and medtech solutions and the early-stage rock tumbler ride that leads to polished thinking and impactful investments.',
            "With age I've also gained a fuller appreciation of my affinity for rough sledding, and the good governance and healthy investor relations that existentially difficult moments sometimes call for."
        ]
    },
    {
        title: 'The Pitchmeister',
        avatar: {
            src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/CherylBW.jpg'
        },
        linkedIn: 'https://www.linkedin.com/in/cheryl-kallem-0433678/',
        name: 'Cheryl Kallem',
        bio: [
            'Cheryl been a member of executive  management in many areas of the financial services industry, including asset management, mutual funds, private investment partnerships and broker/dealers. Her most recent position was serving as the Chief Financial and Operations Officer at First Manhattan & Co., with responsibilities encompassing finance, operations, administration, technology, and compliance. She began her professional career in public accounting.',
            'Cheryl is a Managing Director of Golden Seeds, an angel group that invests in early-stage women led companies. In that role, she is actively involved in selecting and mentoring Golden Seeds companies. Additionally, she serves as a Deal Advisor and often participates in the due diligence process.  She frequently helps entrepreneurs prepare their presentations and pitches as they fundraise.',
            'Cheryl holds a BA in Psychology from Colgate University and an MBA from Rutgers University. She is a Certified Public Accountant. In addition to being a member of.Golden Seeds, she is the vice chair of the Northern Manhattan Improvement Corporation, Treasurer of her coop and a volunteer at the ASPCA. She enjoys mentoring start ups that she meets through various entrepreneurial programs.'
        ]
    },
    {
        title: 'Spreadsheet Wizard',
        avatar: {
            src: 'https://mentorsplusangels-data.s3.amazonaws.com/images/JamesBW.jpg'
        },
        linkedIn: 'https://www.linkedin.com/in/james-dooley-0b03318/',
        name: 'James Dooley',
        bio: [
            'Experienced investor with a focus on alternative credit markets.',
            'Founded or co-founded several asset management businesses, playing a key role in establishing and growing those businesses into successful ventures.  Extensive knowledge of modeling cash flows in NPV-based decision models, analyzing and valuing illiquid, credit sensitive assets.',
            'Now adapting prior valuation and cash flow modeling skills and business management experience into managing a VC portfolio for a family office fund, Seaview Capital.'
        ]
    }
];

export const alumni = [
    {
        company: 'MaMone',
        logo: 'logo-mamome.png',
        founder: 'Nini Fan',
        snippet:
            "is dedicated to advancing women's health, beginning with pregnancy and extending beyond, by utilizing microbiome coupled with machine learning/AI, we precisely identify expectant mothers at elevated risk of pregnancy complications.",
        website: 'https://www.mamome.io'
    },
    {
        company: 'True Scoops',
        logo: 'logo-trueScoops.png',
        founder: 'Shelly Marshall/Kelly Williamson',
        snippet:
            'makes shelf stable ice cream and sauce mixes that create an easy ice cream making experience without the need of an ice cream machine.',
        website: 'https://maketruescoops.com/'
    },
    {
        company: 'Levvy',
        logo: 'logo-levvy.png',
        founder: 'Jeanne Hardy',
        snippet:
            'is the work life platform for professional service organizations that connects teams with everything and everyone they need to do their work, all in one place.',
        website: 'http://levvy.com/'
    },
    {
        company: 'Green Portfolio',
        logo: 'logo-greenPortfolio.png',
        founder: 'Bonnie Gurry/Elizabeth Landau',
        snippet:
            'is the climate fintech company that helps impact-focused financial advisors find affluent millennials using our AI-enhanced matching service.',
        website: 'https://www.greenportfolio.com'
    },
    {
        company: 'Quebracho',
        logo: 'logo-quebracho.png',
        founder: 'Belén Rodríguez',
        snippet:
            'is a Latin food brand that offers better-for-you convenience through heritage-inspired flavors and family recipes from the Argentinian Pampas.',
        website: 'https://www.quebrachomn.com'
    },
    {
        company: 'Integrated Reality Labs',
        logo: 'logo-IRL.png',
        founder: 'Lauren Steidl',
        snippet:
            'enhances game playing, exercise, and human connection with a patent-pending sensor fusion platform that powers large-scale multiplayer games in real life.',
        website: 'https://www.playslap.com/irl'
    },
    {
        company: 'Geem',
        logo: 'logo-geem.png',
        founder: 'Candice Choi',
        snippet:
            'snacks offer a more snackable and satisfying revamp of the traditional sheets, sitting the intersection of several exciting trends: the Korean pop culture wave, sustainability, clean ingredients, and a founder driven brand story.',
        website: 'https://eatgeem.com'
    }
];

export const portfolio = [
    {
        company: 'Allstar',
        logo: 'Allstar.png',
        snippet: 'is a cloud platform that makes it fun and easy for any gamer to create and share high quality, snackable gaming content.',
        website: 'https://allstar.gg/'
    },
    {
        company: 'AdStash',
        logo: 'AdStash.png',
        snippet:
            'helps businesses of all sizes easily generate new streams of income by utilizing programmatic advertising and monetizing their digital screens.',
        website: 'https://www.adstash.com/'
    },
    {
        company: 'Alo.ai',
        logo: 'Alo_ai.png',
        snippet:
            'Communications app for live event organizers uses natural language processing for realtime transcription and translation between 99 languages.',
        website: 'https://www.alo.ai/'
    },
    {
        company: 'Automotus',
        logo: 'automotus.jpg',
        snippet:
            'has built first-of-its-kind automated curb management solutions to help reduce emissions, congestion and safety hazards in our communities',
        website: 'https://www.automotus.co/'
    },
    {
        company: 'Beast',
        logo: 'BeastTag_512x.png',
        snippet: 'Grooming and personal care products for Beasts of all kinds',
        website: 'https://www.getbeast.com/'
    },
    {
        company: 'Beckon',
        logo: 'Beckon.png',
        snippet: 'is the first and only premium ice cream made from lactose-free dairy',
        website: 'https://beckonicecream.com/'
    },
    {
        company: 'Capillary Bio',
        logo: 'CapillaryBiomedicalLogo_TM_400x160.png',
        snippet: 'Developing technologies to simplify diabetes management.',
        website: 'https://www.capillarybio.com/'
    },
    {
        company: 'Carta',
        logo: 'cartalogo.svg',
        snippet: 'helps companies and investors manage their cap tables, valuations, investments, and equity plans.',
        website: 'https://www.carta.com'
    },
    {
        company: 'Chowly',
        logo: 'chowly.png',
        snippet: 'streamlines and digitizes the ordering process, eliminating the need for manual order application.',
        website: 'https://www.chowly.com'
    },
    {
        company: 'CityBlock Health',
        logo: 'cityblock.png',
        snippet: 'provides physical, mental, and social services to care for your whole self and support your daily life.',
        website: 'https://www.cityblock.com/'
    },
    {
        company: 'ClassTag',
        logo: 'classtag-logo.svg',
        snippet: 'connects teachers and families with one easy-to-use app for all their communication needs.',
        website: 'https://home.classtag.com/'
    },
    {
        company: 'CloudFrame',
        logo: 'CloudFrame.png',
        snippet: 'provides automated solutions that reduce mainframe costs and accelerate application modernization and Cloud migrations.',
        website: 'https://cloudframe.com/'
    },
    {
        company: 'Community Gaming',
        logo: 'communitygaming.jpg',
        snippet:
            'removes friction for tournament organizers to host tournaments and for gamers to compete and automatically get paid when they win.',
        website: 'https://www.communitygaming.io/'
    },
    {
        company: 'CorralData',
        logo: 'corralData.png',
        snippet:
            'is a leading AI-driven data analytics platform that enables organizations to access valuable insights and reporting from all their company data with ease.',
        website: 'https://corraldata.com/'
    },
    {
        company: 'Customers.ai',
        logo: 'customersAI.png',
        snippet:
            'provides sales outreach automation and a B2C prospecting data platform. Helping marketers and sales at mid-market B2C businesses.',
        website: 'https://customers.ai/'
    },

    {
        company: 'EdgeHog Technology',
        logo: 'Edgehog LOGO.png',
        snippet: 'Edgehog’s nano-textured anti-reflection glass technology increases annual solar panels output by 6-12%.',
        website: 'https://www.edgehogtech.com/'
    },
    {
        company: 'Epicured',
        logo: 'Epicured.png',
        snippet: 'is a subscription meal delivery service that specializes in Low FODMAP & gluten-free prepared foods.',
        website: 'https://www.epicured.com/'
    },
    {
        company: 'Epilogue Systems',
        logo: 'eplilogueSystems.jpg',
        snippet: 'Provides state of the art computer vision solutions to provide actionable insights into crowd composition and behavior.',
        website: 'https://www.epiloguesystems.com/'
    },
    {
        company: 'FIXE',
        logo: 'FIXE.png',
        snippet: 'combines expertise, technology & automation to streamline bookkeeping and financial reporting for restaurants.',
        website: 'https://www.getmyfixe.com/'
    },
    {
        company: 'Front Range Bio',
        logo: 'Front Range Biosciences.png',
        snippet: 'partners with farmers, combining progressive R&D with modern ag to grow crops with successful yields.',
        website: 'https://www.frontrangebio.com/'
    },
    {
        company: 'Fuego',
        logo: 'fuego.png',
        snippet: 'created a new category of dance footwear.',
        website: 'https://fuegoshoes.com/'
    },
    {
        company: 'FutureStay',
        logo: 'futurestay.png',
        snippet: 'is the leading vacation rental property management software.',
        website: 'https://www.futurestay.com'
    },
    {
        company: 'Goalsetter Inc',
        logo: 'goalsetter.png',
        snippet: 'Education-based banking for the whole family.',
        website: 'https://www.goalsetter.co/'
    },
    {
        company: 'Him for Her',
        logo: 'HFH-Logo-Gold.png',
        snippet: 'bridges the gap between board opportunities and talented women ready for board service.',
        website: 'https://www.himforher.org'
    },
    {
        company: 'HIPPATrek',
        logo: 'HIPAAtrek-New-Logo.jpg',
        snippet: 'Industry leading software to facilitate HIPPA compliance for healthcare facilities.',
        website: 'https://hipaatrek.com/'
    },
    {
        company: 'Instalock Network',
        logo: 'instalock.png',
        snippet: 'is the hub for esports betting. Helping esports bettors through stats, odds, comparisons, picks, tools, and news.',
        website: 'https://www.instalocknetwork.com/'
    },
    {
        company: 'Magellan',
        logo: 'magellan-ai-logo.svg',
        snippet: 'Empowers publishers, agencies and brands to succeed in the rapidly changing world of podcast advertising.',
        website: 'https://www.magellan.ai/'
    },
    {
        company: 'Mathison',
        logo: 'Mathison.png',
        snippet: 'bridges the gap between the most underrepresented job seekers and employers committed to diversity and inclusion.',
        website: 'https://www.mathison.io/'
    },
    {
        company: 'Marstone Inc',
        logo: 'marstone_logo.png',
        snippet: 'is an enterprise-ready digital wealth management and robo-advice platform.',
        website: 'https://allstar.gg/'
    },
    {
        company: 'Measure Studio',
        logo: 'measureStudio.png',
        snippet: 'provides powerful social media analytics, data, and insights that put you in control of your performance.',
        website: 'https://www.medcurainc.com/'
    },
    {
        company: 'Medcura',
        logo: 'MedCura.png',
        snippet:
            'developed a range of products that improve bleeding management while creating antibacterial barriers and improved healing environments.',
        website: 'https://www.medcurainc.com/'
    },
    {
        company: 'Metabob',
        logo: 'metabob_logo-p-500.png',
        snippet: 'All-in-One Tool for Python Debugging.',
        website: 'https://metabob.com/'
    },
    {
        company: 'New Labs',
        logo: 'NewLab.png',
        snippet:
            'home to hundreds of entrepreneurs, engineers, and inventors, solving the world’s biggest challenges with leaders and experts across industries and cities.',
        website: 'https://newlab.com/'
    },
    {
        company: 'New View Surgical',
        logo: 'new-view-logo.png',
        snippet:
            'embarked on a mission to improve the outcomes and efficiency of laparoscopic surgical procedures through the introduction of VisionPort technology.',
        website: 'http://newviewsurg.com/'
    },
    {
        company: 'NYSHEX',
        logo: 'nyshex.png',
        snippet: 'ensures your cargo moves as planned with two-way committed contracts',
        website: 'https://nyshex.com/'
    },
    {
        company: 'Ora Graphene Audio',
        logo: 'Ora-Graphene.png',
        snippet: 'Patented graphene materials designed and optimized for use in acoustic transducers.',
        website: 'https://www.oragraphene.com/'
    },
    {
        company: 'Overwolf',
        logo: 'Overwolf.png',
        snippet: 'A leading platform for creators to develop, share and monetize apps and mods inside video games.',
        website: 'https://www.overwolf.com'
    },
    {
        company: 'Paragon Data Labs',
        logo: 'Paragon_DL_Final_1.png',
        snippet: 'eliminates manual workflows and streamlines compliance processes under one cutting-edge platform.',
        website: 'https://www.paragondatalabs.com/'
    },
    {
        company: 'Polls',
        logo: 'appIcon.svg',
        snippet: 'Allows group polling in text message strings without the need for a separate app or download.',
        website: 'https://pollsforimessage.com/'
    },
    {
        company: 'Powerlytics',
        logo: 'powerlytics.jpg',
        snippet:
            'Provides Income and investable net asset data from individual consumers and businesses.  SaaS service business providing predictive analytics to power marketing and risk analysis.',
        website: 'https://www.powerlytics.com/'
    },

    {
        company: 'Roar For Good',
        logo: 'roar.png',
        snippet: 'A woman-certified technology company dedicated to cultivating safer workplaces in hotels and hospitals.',
        website: 'https://www.roarforgood.com/'
    },
    {
        company: 'Sandstone Diagnostics',
        logo: 'sand-stone.png',
        snippet: 'tools and tech enables point-of-care clinical diagnostics and care by bringing the sample prep to the patient.',
        website: 'https://sandstonedx.com/'
    },
    {
        company: 'ShowX',
        logo: 'ShowX.png',
        snippet:
            'is a one-stop-shop digital platform for musicians to manage their concert bookings, data-base and connect directly with their fans.',
        website: 'https://www.showx.com/'
    },
    {
        company: 'SIA',
        logo: 'sia.png',
        snippet:
            'develops next-generation solutions for reconstructive and aesthetic surgery that improve the surgeon and patient experience.',
        website: 'https://sia.health/'
    },
    {
        company: 'SplitGate',
        logo: 'Splitgate.png',
        snippet: 'A free-to-play FPS video game with player-controlled portals, playable on multiple platforms: "Halo meets Portal".',
        website: 'https://www.splitgate.com'
    },
    {
        company: 'ThriveFantasy',
        logo: 'Thrive.png',
        snippet: 'is a daily fantasy sports and esports platform offering simple and engaging contests and player props.',
        website: 'https://allstar.gg/'
    },
    {
        company: 'Tire Agent',
        logo: 'TireAgent.svg',
        snippet: "is the Internet's largest selection of tires, rims and wheels shipped anywhere in the continental U.S. for free",
        website: 'https://www.tireagent.com/'
    },
    {
        company: 'US Integrity',
        logo: 'usintegrity-logo.png',
        snippet:
            'offers customized sports-integrity solutions for NCAA conferences, universities, professional leagues, and individual teams',
        website: 'https://www.usintegrity.com/'
    },
    {
        company: 'Wagmo',
        logo: 'wagmo.png',
        snippet: "provides the best pet insurance and pet wellness plans that won't break the bank.",
        website: 'https://wagmo.io'
    },
    {
        company: 'Winzo',
        logo: 'WinZo.png',
        snippet:
            'Leading social gaming app in India where users play skill games in multiple formats and languages to win cash and other prizes.',
        website: 'https://www.winzogames.com'
    }
];

export const criteria = [
    {
        id: '0',
        title: 'Grit (Leadership / CEO)',
        text: 'Commitment, intensity, leadership and executive skills and passion of the founding team',
        maxScore: 3
    },
    {
        id: '1',
        title: 'Prior Capital Raise',
        text: 'Total capital raised prior to the current round inclusive of friends and family, but excluding grant or contest award amounts (<$50k to >$100k)',
        maxScore: 3
    },
    {
        id: '2',
        title: 'Full Time Employees',
        text: 'CEO/founder and any other full-time partners or employees. Outsourced, contracted or part-time employees not included. (<1 to >5 people)',
        maxScore: 3
    },
    { id: '3', title: 'Stage of Development', text: 'Readiness for commercial deployment (no MVP to full product)', maxScore: 3 },
    {
        id: '4',
        title: 'Product Market Fit',
        text: 'Limited demand or unclear market fit, to clear and strong product-market fit',
        maxScore: 3
    },
    {
        id: '5',
        title: 'Competitive Moat',
        text: 'Limited moat, vulnerable to competition to strong moat, significant competitive a. is not easily replicable.',
        maxScore: 3
    },
    {
        id: '6',
        title: 'Go to Market Plan',
        text: 'degree of thought given to sales channels, channel management, key opinion leaders and partners, distributors and or direct sales efforts.',
        maxScore: 3
    },
    {
        id: '7',
        title: 'Initial Traction',
        text: 'Recognized revenues from sale of the product or service and/or signed contracts. (No traction to >$100k ARR)',
        maxScore: 3
    },
    {
        id: '8',
        title: 'Experience of the Team',
        text: 'Executive experience of the team members in a business or market similar to the current venture. (No experience to > 5y)',
        maxScore: 3
    },
    {
        id: '9',
        title: 'Clear Monetization Plan',
        text: 'Path to REV generation (no clear strategy or flawed strategy, pie in the sky to strong, well-defined, detailed REV strategy backed by resources behind REV generation)',
        maxScore: 3
    },
    {
        id: '10',
        title: 'Scalability',
        text: 'ability to grow and expand its operations, revenue, and impact efficiently and sustainably. This includes the potential to increase customer base, enter new markets, enhance product/service offerings, and scale operations',
        maxScore: 3
    },
    { id: '11', title: 'addressable market size', text: '< $100M to > $1B', maxScore: 3 }
];

export default config;
