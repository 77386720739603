import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@mui/material';

// Project imports
import { errorExists, getErrorText } from 'utils/errors';
import { interestValidation } from './validation';

// ==============================|| CREATE INTEREST POPUP ||============================== //

const CreateInterestPopUp = ({ open, handleClose }) => {
    const [errors, setErrors] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [formData, setFormData] = useState({ progID: '1' });
    const { familyName = '', givenName = '', email = '', companyName = '', interestText = '' } = formData;

    const updateData = (data) => {
        // Remove error if present
        if (errors.length > 0) {
            let newErrors = [];
            Object.keys(data).forEach((key) => {
                newErrors = errors.filter((item) => item.field !== key);
            });
            setErrors(newErrors);
            if (newErrors.length === 0) {
                setErrorMessage('');
            }
        }
        setFormData({ ...formData, ...data });
    };

    const resetError = (field) => {
        const updatedErrors = [...errors.filter((item) => item.field !== field)];
        setErrors(updatedErrors);
        if (updatedErrors.length === 0) {
            setErrorMessage('');
        }
    };

    const handleSubmit = () => {
        const formErrors = interestValidation({ formData });
        setErrors(formErrors);
        if (formErrors.length > 0) {
            setErrorMessage('Please correct the highlighted applications');
            return;
        }
        setErrorMessage('');

        handleClose({ save: true, application: formData });
    };

    return (
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
            <DialogTitle>Application Form: m+a accelerator</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h2" color="error" sx={{ mb: 2 }}>
                            Unfortunately our current application deadline has passed
                        </Typography>
                        <Typography>
                            Thank you for your interest in the m+a acelerator program. If you wish to be notified when we start taking
                            applications for our next program, please register below.
                        </Typography>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="First Name"
                            value={givenName}
                            onChange={(e) => {
                                updateData({ givenName: e.target.value });
                            }}
                            onFocus={() => resetError('givenName')}
                            helperText={getErrorText({ errors, field: 'givenName' })}
                            error={errorExists({ errors, field: 'givenName' })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            value={familyName}
                            onChange={(e) => {
                                updateData({ familyName: e.target.value });
                            }}
                            onFocus={() => resetError('familyName')}
                            helperText={getErrorText({ errors, field: 'familyName' })}
                            error={errorExists({ errors, field: 'familyName' })}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            fullWidth
                            label="Email address"
                            value={email}
                            onChange={(e) => {
                                updateData({ email: e.target.value });
                            }}
                            onFocus={() => resetError('email')}
                            helperText={getErrorText({ errors, field: 'email' })}
                            error={errorExists({ errors, field: 'email' })}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            fullWidth
                            label="Company Name"
                            value={companyName}
                            onChange={(e) => {
                                updateData({ companyName: e.target.value });
                            }}
                            onFocus={() => resetError('companyName')}
                            helperText={getErrorText({ errors, field: 'companyName' })}
                            error={errorExists({ errors, field: 'companyName' })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            label="Interest (optional)"
                            placeholder="Briefly describe your company and why uou are interested in the program"
                            rows={3}
                            value={interestText}
                            onChange={(e) => {
                                updateData({ interestText: e.target.value });
                            }}
                            onFocus={() => resetError('interestText')}
                            helperText={getErrorText({ errors, field: 'interestText' })}
                            error={errorExists({ errors, field: 'interestText' })}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {errorMessage && (
                            <Typography variant="h4" color="error" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleClose({ save: false })} variant="outlined" size="small" color="secondary" sx={{ mr: 2 }}>
                    Cancel
                </Button>
                <Button onClick={handleSubmit} variant="contained" size="small" color="warning" sx={{ mr: 2 }}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

CreateInterestPopUp.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func
};

export default CreateInterestPopUp;
